import React from 'react'
import { useContext } from 'react'
import { Contextet } from '../Context/Context'
import Fade from 'react-reveal/Fade';
import { useTranslation} from "react-i18next";
import { useEffect } from 'react';
import { Link } from 'react-router-dom';



export default function WriteUs() {

let { setNote , notError , myComment   , getreview , setNoteError , note}=  useContext(Contextet)

let {t} = useTranslation()

useEffect(() => {

  return () => {
    setNoteError('')
    setNote({name : '' , comment : '' , review : '' , user : localStorage.getItem("user_id") , room : ''})
   
  }
}, [])


  return (
    <div>

<div className='comment pt-4'>
        <h3 className='cs'>{t('comment')}</h3>
        <div className="mb-3">
    <label htmlFor="exampleInputEmail1" className="form-label">Name :</label>
    <input type="text" onChange={getreview} className="form-control" aria-describedby="emailHelp" placeholder='your Name' name='name' />
  </div>
  <div className="mb-3">
    <label htmlFor="exampleInputPassword1" className="form-label">your room number:</label>
    <input type="text" onChange={getreview} className="form-control" name='room'  placeholder='kindly enter your room number' />
  </div>
  <div className="mb-3">
    <label htmlFor="exampleInputPassword1" className="form-label">{t('Comments')} :</label>
    <input type="text" onChange={getreview} className="form-control" name='comment'  placeholder='we are waiting for any comments' />
  </div>
  <div className="mb-3 ">
  <label htmlFor="exampleInputPassword1" className="form-label">{t('review')} :</label>
<input type="number"  onChange={getreview} className="form-control" name='review'  placeholder='your review out of 10' />
  </div>
  <div className='pb-3'>
    {t('User_Name')}  : {localStorage.getItem("username")}
  </div>
  <div className='d-flex justify-content-between align-items-center'>
  <Link to='/review' className="btn btn-primary mb-3 text-white">{t('Back')}</Link>
  <button type="submit" onClick={myComment} className="btn btn-primary mb-3 ">{t('send')} &#128512;</button>
  </div>
  <Fade right>
  <div className='text-center text-danger h3'>{notError}</div>
  </Fade>
        </div>
    </div>
  )
}
