import React, { useContext, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, NavLink } from "react-router-dom";
import { Contextet } from "../Context/Context";
import { TaxiUse } from "../Context/TaxiContext";
import { useTranslation } from "react-i18next";
import Dropdown from "react-bootstrap/Dropdown";
import i18next from "i18next";
import { GrLanguage } from "react-icons/gr";
import { FaLanguage, FaUserAlt } from "react-icons/fa";
import { BiTrip } from "react-icons/bi";
import { RiContactsFill, RiLockPasswordFill } from "react-icons/ri";
import { FiLogIn } from "react-icons/fi";
import { MdOutlineLogout } from "react-icons/md";
import { CgUserList } from "react-icons/cg";
import './Navbar.css';

export default function Navbars() {
  let { setChange, setDepature } = useContext(Contextet);
  let { setTaxi } = useContext(TaxiUse);

  let { t } = useTranslation();

  let u = localStorage.getItem("token") == null;


  const token = localStorage.getItem('token');
  useEffect(() => {
      if (token == null) {
        setChange(true);
        setTaxi("");
        setDepature(null);
      } else {
        fetch('https://kiro-travel.herokuapp.com/api/v1/users/me/', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        .then(response => response.json())
        .then(data => {
          // console.log(data)
          if(data?.code == "token_not_valid") {
            localStorage.clear();
            setChange(true);
            setTaxi("");
            setDepature(null);
            window.location.reload();
            window.location.href = "/";
          }
        })
        .catch(error => {
          console.error(error);
        });
      }
        
  }, [token])



  const handleLogoUt = () => {
    
    // fetch('https://kiro-travel.herokuapp.com/api/v1/users/logout/', {
    //   method: 'POST',
    //   headers: {
    //     'Authorization': `Bearer ${token}`
    //   }
    // })
    // .then(response => response.json())
    // .then(data => {
    //   console.log(data);
    // })
    // .catch(error => {
    //   console.error(error);
    // });

    localStorage.clear();
    setChange(true);
    setTaxi("");
    setDepature(null);
    window.location.reload();
    window.location.href = "/";
  }

  return (
    <>
      <Navbar expand="lg">
        <Container className="pt-2 pb-2">
          <Link to="/" className="h4 m-0">
            <span className="kiro">Kiro</span> <span className="travel">Travel</span>
          </Link>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse
            id="navbarScroll"
            className="justify-content-between"
          >
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            ></Nav>
            <ul className="data m-0">
              <Dropdown className="lang">
                <Dropdown.Toggle className="lang">
                  <GrLanguage size={25} />
                  {/* <FaLanguage size={35} /> */}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <div className="d-flex justify-content-center align-items-center flex-column">
                    <button
                      className="btn w-100 d-flex justify-content-around align-items-center"
                      onClick={() => i18next.changeLanguage("fr")}
                    >
                      french{" "}
                      <img
                        src="Flag_of_France.svg.webp"
                        width="20px"
                        alt="french"
                        className="rounded-circle"
                      />
                    </button>
                    <button
                      className="btn w-100 d-flex justify-content-around align-items-center mt-3"
                      onClick={() => i18next.changeLanguage("en")}
                    >
                      English{" "}
                      <img
                        src="download.png"
                        width="20px"
                        alt="english"
                        className="rounded-circle"
                      />
                    </button>
                  </div>
                </Dropdown.Menu>
              </Dropdown>

              {u ? (
                <>
                  <NavLink className="btn btn-primary d-flex align-items-center text-white" to="/register">
                    <RiContactsFill className="mr-2"/>
                    {t("Register")}
                  </NavLink>
                  <NavLink className="btn btn-success d-flex align-items-center text-white" to="/login">
                    <FiLogIn className="mr-2"/>
                    {t("login")}
                  </NavLink>
                </>
              ) : (
                <>
                  <div className="profile">
                    <Dropdown className="lang">
                      <Dropdown.Toggle className="lang">
                        {/* <FaUserAlt size={20} /> */}
                        <CgUserList size={30} />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="p-3">
                        <div className="d-flex justify-content-center align-items-center flex-column mb-3">
                          <Link
                            to="Trips"
                            className="d-flex justify-content-around align-items-center w-100"
                          >
                            <BiTrip />
                            <p className="w-menu">Trips</p>
                          </Link>
                        </div>
                        <div className="d-flex justify-content-center align-items-center flex-column mb-3">
                          <Link
                            to="profile"
                            className="d-flex justify-content-around align-items-center w-100"
                          >
                            <FaUserAlt />
                            <p className="w-menu">Profile</p>
                          </Link>
                        </div>
                        <div className="d-flex justify-content-center align-items-center flex-column mb-3">
                          <Link
                            to="password"
                            className="d-flex justify-content-around align-items-center w-100"
                          >
                            <RiLockPasswordFill />
                            <p className="w-menu">Password</p>
                          </Link>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <NavLink
                    to="/"
                    className="Navbar_logOutButton"
                    onClick={() => handleLogoUt()}
                  >
                    <MdOutlineLogout size={20} className="mr-2"/>
                    {t("log_out")}
                  </NavLink>
                </>
              )}
            </ul>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
