import React from 'react'
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { useContext } from 'react';
import { Contextet } from '../Context/Context';
import { useEffect } from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

export default function Table() {

    let { pastTrips,getPastTrips} = useContext(Contextet)

useEffect(() => {
    getPastTrips(1)
}, [])


    const column = [
        { field: 'id', headerName: 'Reservation id', width: 90 },
        {
          field: 'first_name',
          headerName: 'First name',
          flex : 1 ,
        },
        {
          field: 'last_name',
          headerName: 'Last name',
          flex : 1 ,
        },
        {
          field: 'date',
          headerName: 'date',
          flex : 0.5 ,
        },
       {
          field: 'seats_booked',
          headerName: 'seats booked',
          flex : 0.5 ,
        },
      ];
      
    
  return (
    <div className='mt-3'>
        {
            pastTrips != null ? (
                <Box sx={{ height: 300, width: '100%' }}>
                <DataGrid
                  rows={pastTrips.results }
                  columns={column}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  components={{ 
                    Pagination: 'none',
                  }}
      
                />
                    <Pagination count={pastTrips.count} color="primary" className='mt-3 mb-3 center' onChange={(e)=> getPastTrips(e.target.innerText)} />
              </Box>
            ) : ('')
        }
  
    </div>
  )
}
