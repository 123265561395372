import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import './taxiBookingTrip.css'
import { useEffect } from 'react'
import axios from 'axios'
import { toast } from 'react-hot-toast'



function TaxiBookingTrip() {

  const [dataFromEndPoint, setDataFromEndPoint] = useState({});
  const [loadingEndPoint, setLoadiingEndPoint] = useState(true);
  const [loadingModification, setLoadiingModification] = useState(false);
  const idTrip = useLocation()
  const tripParam = new URLSearchParams(idTrip.search)
  const id = tripParam.get('id')


  useEffect(() => {

    const token = localStorage.getItem('token');

    const headers = {
      Authorization: `Bearer ${token}`
    };

    // console.log(id)
    setLoadiingEndPoint(true)
    axios.get(`https://kiro-travel.herokuapp.com/api/v1/shuttle/shuttle-reservations/?id=${id}`, {headers})
    .then(res => {
      setDataFromEndPoint(res?.data?.data[0])
    })
    .catch(err => {
      console.log(err)
    })
    .finally(() => setLoadiingEndPoint(false))


  },[])

  const handleModifaied = (e) => {
    e.preventDefault()
    const token = localStorage.getItem('token');

    const headers = {
      Authorization: `Bearer ${token}`
    };

    const obj = {
      trip_id: dataFromEndPoint?.trip.id,
      reservation_id: dataFromEndPoint?.id
    }
    setLoadiingModification(true)
    axios.post(`https://kiro-travel.herokuapp.com/api/v1/shuttle/shuttle-modification-request/`, obj, {headers})
    .then(res => {
      // console.log(res)
      if(res?.status === 200 ) {
        toast.success('Trip Modification Requested')
      } else {
        console.log(res)
      }
    })
    .catch(err => {
      console.log(err)
    })
    .finally(() => setLoadiingModification(false))

  }

  if(loadingEndPoint) {
    return (
      <div className="loading-container">
        <div className="loading">Loading...</div>
      </div>
    )
  }


  return (
<>
<div className="logo text-center zindex-dropdown border-0 m-auto pt-3 ">
  <img
    src="https://kirotravel.com/wp-content/uploads/2021/04/NOUVEAU-LOGO-SANS-COEUR-PNG.png"
    alt=""
  />
</div>
<div className="title text-center my-3">
  <h1>Kiro Travel</h1>
</div>
<div className="status bg-success  w-100 w-sm-auto text-white text-center p-3 m-auto mt-4">
  <h3>Your Booking Request Has Been Confirmed</h3>
</div>
<div className="content d-flex m-auto py-5 flex-column gap-5">
  <p className='pb-3'>
    <span className="d-flex justify-content-start">
      Hello, 
      {dataFromEndPoint?.first_name}
    </span>
    <br />
    Your booking request has been Confirmed  <br />
      Please contact us if you need modification.
  </p>
  <div className='d-flex flex-column justify-content-center flex-md-row'>
    <button className=" bg-success rounded-3 border-0 p-2 px-3 m-1 m-md-3">
      <a className="text-decoration-none text-white" href="tel:+33644915310">
        Contact US &gt;&gt;{" "}
      </a>
    </button> <br />
    <button disabled={loadingModification} onClick={(e) => handleModifaied(e) } className=" rounded-3 border border-dark p-2 px-3 bg-transparent m-1 m-md-3">
       {loadingModification ? 'Loading...' : 'Modified Trip'}
    </button>
  </div>
  <hr />
</div>
<div className="booking-details m-auto mt-4">
  <h3>Your Booking Details</h3>
  <div className="details text-secondary pt-5">
    <p>
      <span className="font-weight-bold">Booking ID: </span> 
      {dataFromEndPoint?.id}
      <br />
      <span className="font-weight-bold">Booking Status: </span>Confirmed
      <br />
      <span className="font-weight-bold">Passengers: </span>

      {dataFromEndPoint?.seats_booked}

      <br />
    </p>
  </div>
</div>
<div class="trip-details m-auto mt-5 d-flex flex-column flex-md-row justify-content-between border p-4">
  <div class="from">
    <h3 class="text-secondary">From</h3>
    <br />
    <h6 title={dataFromEndPoint?.trip?.pickup_location}>

      {dataFromEndPoint?.trip?.pickup_location}

    </h6>
  </div>

  <div class="arrow align-self-center d-none d-md-block">
    <img src="https://img.icons8.com/ios/50/000000/long-arrow-right.png" />
  </div>

  <div className='line d-sm-none'></div>

  <div class="to">
    <h3 class="text-secondary">To</h3>
    <br />
    <h6 title={dataFromEndPoint?.trip?.drop_location}>

      {dataFromEndPoint?.trip?.drop_location}

    </h6>
  </div>
</div>
<div className="date-time m-auto text-center border bg-secondary text-white p-3 ">
  <h3>Date &amp; Time</h3>
  <br />
  <h6>
    {dataFromEndPoint?.date} :  {dataFromEndPoint?.trip.time}
  </h6>
</div>
<div className="personal-details">
  <h3 className="text-secondary text-center mt-5">Personal Details</h3>
  <div className="details m-auto mt-5">
    <table className="table table-bordered">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Name</th>
          <th scope="col">Phone</th>
          <th scope="col">Email</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">1</th>
          <td>
            {dataFromEndPoint?.first_name}
          </td>
          <td>
          {dataFromEndPoint?.user.phone}
          </td>
          <td title={dataFromEndPoint?.user.email}>
            {dataFromEndPoint?.user.email.slice(0,10)}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
</>

  )
}

export default TaxiBookingTrip