import React from "react";
import Footer from "./Footer";
import Fade from "react-reveal/Fade";
export default function Contacts() {

  return (
    <div className="mt-3">
      <Fade up>
        <div className="about">
          <h2>Kiro Travel</h2>
          <p>Airport & Train Station transfer -</p>
          <p>City transfers & ski transfers -</p>
          <p>Airport & Hotel Shuttle -</p>
          <p>Hourly hire & Events -</p>
        </div>
      </Fade>
      <Footer />
    </div>
  );
}
