import React from 'react'
import { Contextet } from '../Context/Context';
import { useContext } from 'react';
import Fade from 'react-reveal/Fade';
import StarRatingComponent from 'react-star-rating-component';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { AiFillStar } from "react-icons/ai";
import { useTranslation} from "react-i18next";





export default function Comment() {

    let {reviews, notError , myComment  , review , getreview}=  useContext(Contextet)

    let {t} = useTranslation()

useEffect(()=>{
  reviews()
},[])
  return (
    <div>
<Link to='/write' className=''>
 <div className="writeRe d-flex justify-content-around p-3 mb-4 flex-wrap align-items-center">
 
    <div>
    <button className='btn btn-primary'>
   {t('writeReview')}
    </button>
  </div>
   <div>
     <AiFillStar/>
     <AiFillStar/>
     <AiFillStar/>
     <AiFillStar/>
     <AiFillStar/>
  </div>
 </div>
 </Link>
<h2 className='pb-3 cs'>{t('customer')} : &#128525;</h2>

        {
          review != null && review != "" ? (
          (
           review.map((e)=>
           <div className='bg-light p-3 review' key={e.id}>
            <div>
            <p className='text-success h1'>{e.name}</p>
            <p className='text-blue h2'>{e.review}</p>
            <div>
            <StarRatingComponent 
          name="rate1" 
          starCount={10}
          value={e.rating}
          className="font"
        />
            </div>
            </div>
            </div>   
           ) 
        
          )
            
        ) : (
        (
        <div className='h1 text-center text-danger'>
           {t('nope')}
          </div>
        )
        )    
        }
       
      
    </div>
  )
}
