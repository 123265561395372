import React from 'react'
import { useEffect } from 'react';
import Footer from './Footer'
import { useTranslation} from "react-i18next";



export default function Privacy() {

  let {t} = useTranslation()

  function myFunction() {
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    myFunction()
  }, [])



  return (
    <div className='mt-3 design'>
    <h2 className='h1 text-center mb-5 text-danger'>{t('privacy')}</h2>
    <p><span className='text-primary'>www.kirotravel.com</span> {t('personal')}</p>
    <p>{t('overview_policy')}</p>
    <h2 className='h1'>{t('activitis')}</h2>
    <p>{t('driver')}</p>
    <h2 className='h1'>{t("data_proceed")}</h2>
    <p><span className='text-primary'>www.kirotravel.com</span> {t('ways')}</p>
    <p>{t('marital')}</p>
    <p>{t('technical')}</p>
    <p>{t('analtiycs')}</p>
    <h2 className='h1'>{t('sensitive')}</h2>
    <p>{t('GDPR')}</p>
    <p><span className='text-primary'>www.kirotravel.com</span> {t('proccess_info')}</p>
    <h2 className='h1'>{t('How_data')}</h2>
    <p><span className='text-primary'>www.kirotravel.com </span>{t('laws')}</p>
    <div>
      <ul>
        <li>{t('personal_li')}</li>
        <li>{t('collection_personal')}</li>
        <li>{t('permission_data')}</li>
        <li>{t('security')}</li>
        <li>{t('your_right')}</li>
      </ul>
    </div>
    <h2 className='h1'>{t('web_info')}</h2>
    <p>{t('ux')}</p>
    <h2 className='h1'>{t('Newsletter')}</h2>
    <p>{t('news_services')}</p>
    <h2 className='h1'>{t('advertise')}</h2>
    <p><span className='text-primary'>www.kirotravel.com</span>{t('shar_data')}</p>
    <h2 className='text-primary'>{t('who_data')}</h2>
    <p><span className='text-primary'>www.kirotravel.com </span>{t('network')}</p>
    <h2 className='h1'>{t('genral_data')}</h2>
    <p>{t('under_law')}</p>
    <h2 className='h1'>{t('european_union')}</h2>
    <p><span className='text-primary'>www.kirotravel.com </span>{t('answer_union')}</p>
    <h2 className='h1'>{t('delete')}</h2>
    <p>{t('stored')}<span className='text-primary'>www.kirotravel.com</span></p>
    <h2 className='h1'>{t('evidence')}</h2>
    <p>{t("evidence_answer")}</p>
    <h2 className='h1'>{t('cookies')}</h2>
    <p>{t('cookies_explain')}<a href="https://www.cnil.fr/fr/cookies-traceurs-que-dit-la-loi." className='text-dangerss' target='_blank'>https://www.cnil.fr/fr/cookies-traceurs-que-dit-la-loi.</a></p>
    <h2 className='h1'>{t('changes_policy')}</h2>
    <p>{t('development_policy')}</p>
    <p>{t('questions')} <span className='text-danger'>contact@kirolimo.fr</span></p>

    <Footer />
    </div>
  )
}
