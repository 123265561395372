import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Card } from "react-bootstrap";
import { FiMail } from "react-icons/fi";
import { IconContext } from "react-icons";
import { Contextet } from "../Context/Context";
import { FaHotel } from "react-icons/fa";
import { AiFillPhone } from "react-icons/ai";

export default function Profile() {
  let { t } = useTranslation();

  let {
    ConfirmPhone,
    updatePhone,
    setUserNewPhone,
    me2,
    me3,
    setUserHotel,
    changeMail,
    updateMail,
    ConfirmMail,
    updateHotel,
    ConfirmHotel,
  } = useContext(Contextet);

  return (
    <div>
      <div className="mt-5 pt-5">
        <div className="mb-5 h3 bold">
          {t("User_Name")} : {localStorage.getItem("username")}
        </div>

        <Form.Group
          className="mb-3 d-flex flex-column"
          controlId="formBasicEmail"
        >
          <Form.Control
            defaultValue={localStorage.getItem("userEmail")}
            onChange={(e) => changeMail(e)}
            type="email"
            placeholder="Enter your Email"
          />
          <Button
            className="my-4"
            id="test"
            ref={me2}
            onClick={(e) => updateMail()}
          >
            {t("update_mail")}
          </Button>
          <div ref={me3} id="test2" className="updateMail d-none">
            <Card style={{ width: "fit-content" }} className="p-3 m-auto">
              <Card.Body className="text-center">
                <Card.Title className="text-center text-danger">
                  {t("emailUpdate")}
                </Card.Title>
                <div className="my-3">
                  <IconContext.Provider value={{ size: "5em", color: "red" }}>
                    <FiMail />
                  </IconContext.Provider>
                </div>
                <h2 className="bold h5 ">{t("emailConfirm")}</h2>
              </Card.Body>
              <div className="text-center">
                <Button
                  to="/profile"
                  className="btn btn-primary text-white mx-3"
                  onClick={ConfirmMail}
                >
                  {t("Yes")}
                </Button>
                <Button
                  onClick={(e) =>
                    document.getElementById("test2").classList.remove("d-flex")
                  }
                  to="/profile"
                  className="btn btn-primary text-white"
                >
                  {t("No")}
                </Button>
              </div>
            </Card>
          </div>
        </Form.Group>

        <Form.Group
          className="mb-3 d-flex flex-column"
          controlId="formBasicEmail"
        >
          <Form.Control
            defaultValue={localStorage.getItem("phone")}
            type="number"
            onChange={(e) => setUserNewPhone(e.target.value)}
            // type="email"
            placeholder={t("phoneData")}
          />
          <Button className="my-4" id="test" onClick={(e) => updatePhone()}>
            {t("phoneUpdate")}
          </Button>
          <div id="updatePhone" className="updateMail d-none">
            <Card style={{ width: "fit-content" }} className="p-3 m-auto">
              <Card.Body className="text-center">
                <Card.Title className="text-center text-danger">
                  {t("phoneNumberUpdate")}
                </Card.Title>
                <div className="my-3">
                  <IconContext.Provider value={{ size: "5em", color: "red" }}>
                    <AiFillPhone />
                  </IconContext.Provider>
                </div>
                <h2 className="bold h5 ">{t("phoneConfirm")}</h2>
              </Card.Body>
              <div className="text-center">
                <Button
                  className="btn btn-primary text-white mx-3"
                  onClick={ConfirmPhone}
                >
                  {t("Yes")}
                </Button>
                <Button
                  onClick={(e) =>
                    document
                      .getElementById("updatePhone")
                      .classList.remove("d-flex")
                  }
                  className="btn btn-primary text-white"
                >
                  {t("No")}
                </Button>
              </div>
            </Card>
          </div>
        </Form.Group>

        <Form.Group
          className="mb-3 d-flex flex-column"
          controlId="formBasicEmail"
        >
          <Form.Select
            aria-label="Default select example"
            onChange={(e) => setUserHotel(e.target.value)}
            className="w-100 p-2"
            name="hotel_name"
            defaultValue={localStorage.getItem("hotel")}
          >
            <option>{t("Choose_your_hotel")}</option>

            <option value="M3 Hôtel &amp; Résidence Ferney Geneva Airport">
              M3 Hôtel &amp; Résidence Ferney Geneva Airport
            </option>

            <option value="Zenitude Hôtel-Résidences - Ferney Voltaire">
              Zenitude Hôtel-Résidences - Ferney Voltaire
            </option>

            <option value="APPART&#x27;CITY CONFORT Genève Aéroport Ferney Voltaire, France">
              APPART&#x27;CITY CONFORT Genève Aéroport Ferney Voltaire, France
            </option>

            <option value="NOVOTEL Geneve Aeroport Ferney Voltaire, France">
              NOVOTEL Geneve Aeroport Ferney Voltaire, France
            </option>

            <option value="Hotel F1 Ferney Voltaire, France">
              Hotel F1 Ferney Voltaire, France
            </option>

            <option value="PREMIERE CLASS Ferney Voltaire, France">
              PREMIERE CLASS Ferney Voltaire, France
            </option>

            <option value="IBIS BUDGET Saint Genis Pouilly, France">
              IBIS BUDGET Saint Genis Pouilly, France
            </option>

            <option value="KYRIAD Saint Genis Pouilly, France">
              KYRIAD Saint Genis Pouilly, France
            </option>

            <option value="PREMIERE CLASS Saint Genis Pouilly, France">
              PREMIERE CLASS Saint Genis Pouilly, France
            </option>

            <option value="IBIS BUDGET Saint Genis Pouilly, France">
              IBIS BUDGET Saint Genis Pouilly, France
            </option>

            <option value="Appart&#x27;hôtel Adagio Saint Genis Pouilly, Fance">
              Appart&#x27;hôtel Adagio Saint Genis Pouilly, Fance
            </option>

            <option value="BEST WESTERN Park Hotel Saint Genis Pouilly">
              BEST WESTERN Park Hotel Saint Genis Pouilly
            </option>

            <option value="WOODS Hotel Genève Thoiry">
              WOODS Hotel Genève Thoiry
            </option>

            <option value="Hôtel JIVA HILL Resort">
              Hôtel JIVA HILL Resort
            </option>

            <option value="Hôtel BOIS JOLY">Hôtel BOIS JOLY</option>

            <option value="Hotel de France Ferney Voltaire, ">
              Hotel de France Ferney Voltaire,{" "}
            </option>

            <option value="Hôtel de France Saint Genis Pouilly, ">
              Hôtel de France Saint Genis Pouilly,{" "}
            </option>

            <option value="Residhome Prévessin Moen Le Carré d&#x27;Or">
              Residhome Prévessin Moen Le Carré d&#x27;Or
            </option>

            <option value="Hotel Motel D&#x27;ornex">
              Hotel Motel D&#x27;ornex
            </option>

            <option value="SEJOUR &amp; AFFAIRES Hotel Saint Genis Pouilly">
              SEJOUR &amp; AFFAIRES Hotel Saint Genis Pouilly
            </option>
          </Form.Select>
          <div ref={me3} id="Hotel" className="updateMail d-none">
            <Card style={{ width: "fit-content" }} className="p-3 m-auto">
              <Card.Body className="text-center">
                <Card.Title className="text-center text-danger">
                  {t("hotelUpdate")}
                </Card.Title>
                <div className="my-3">
                  <IconContext.Provider value={{ size: "5em", color: "red" }}>
                    <FaHotel />
                  </IconContext.Provider>
                </div>
                <h2 className="bold h5 ">{t("confirmHotel")}</h2>
              </Card.Body>
              <div className="text-center">
                <Button
                  to="/profile"
                  className="btn btn-primary text-white mx-3"
                  onClick={ConfirmHotel}
                >
                  {t("Yes")}
                </Button>
                <Button
                  onClick={(e) => me3.current.classList.remove("d-flex")}
                  to="/profile"
                  className="btn btn-primary text-white"
                >
                  {t("No")}
                </Button>
              </div>
            </Card>
          </div>
          <Button className="my-4" onClick={updateHotel}>
            {t("update_hotel")}
          </Button>
        </Form.Group>
      </div>
    </div>
  );
}
