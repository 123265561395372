/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-undef */
import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom';
import { AiOutlineNumber } from 'react-icons/ai'
import { Contextet } from '../Context/Context';

import Fade from 'react-reveal/Fade';
import { useTranslation } from "react-i18next";
import { useEffect } from 'react';
import './Bus.css'
import { MdClose, MdOutlineDirectionsBusFilled } from 'react-icons/md';
import { Button } from 'react-bootstrap';
import { loadGoogleMapsScript } from './LoadScripting';
import axios from 'axios';

export default function Bus() {
  const [seatsAndLuggage, setSeatsAndLuggage] = useState({
    seats:1,
    luggage:0,
    ski:0
  })
  const [showModal, setShowModal] = useState(false)
  const [roomNumber ,setRoomNumber] = useState('')
  const [flightNumber ,setFlightNumber] = useState('')
  const [firstName ,setFirstName] = useState('')
  const [lastName ,setLastName] = useState('')
  const [priceSki, setPriceSki] = useState({})
  const [directionResponse, setDirectionResponse] = useState(null);
  const [distance , setDistance] = useState('');
  const [duration , setDuration] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);


  let { t } = useTranslation()

  let { userForm, latest, busError, userError, setDepature, setUserForm, form } = useContext(Contextet)


  useEffect(() => {

      if(!Array.isArray(JSON.parse(window.localStorage.getItem('dataBus')))) {
        window.localStorage.setItem('dataBus', JSON.stringify(latest));
      }

      return () => {
        window.localStorage.removeItem('dataBus')
      }

  },[latest])

  const dataBusFromLocalStorage = JSON.parse(window.localStorage.getItem('dataBus')) 



  var x = latest ? latest : dataBusFromLocalStorage



  async function calculateRoute() {
    const dirctionServices = new google.maps.DirectionsService();
    const results = await dirctionServices.route({
        origin: "البحرين",
        destination: "اسوان مركز ادفو، Mansheyat as Sadr, Hada'iq El Qobbah, Egypt",
        travelMode: google.maps.TravelMode.DRIVING,
    })

    // console.log(results)

    setDirectionResponse(results)
    setDistance(results.routes[0].legs[0].distance.text)
    setDuration(results.routes[0].legs[0].duration.text)
  }


  useEffect(() => {
    setUserForm({ seats_booked: seatsAndLuggage.seats, ski:seatsAndLuggage.ski, no_of_bags: seatsAndLuggage.luggage, room: roomNumber, flight: flightNumber , first_name: localStorage.getItem('username'), last_name: localStorage.getItem('username'), phone: localStorage.getItem('phone'), id: localStorage.getItem("busId"), user: localStorage.getItem("user_id")})
    loadGoogleMapsScript('AIzaSyBqB6CgEEbTrE5b2LV_xC4DLOtag9wBPaQ', ['places'])
    .then(() => {
      calculateRoute()
      setIsLoaded(true);
    })
    .catch((error) => {
      console.error(error);
    });
    
    

    // console.log(duration)

    return () => {
      setUserForm({ first_name: '', price_from_conuter: 0 , last_name: '', phone: '', no_of_bags: '', id: localStorage.getItem("busId"), seats_booked: '', user: localStorage.getItem("user_id"), room: '' })
      localStorage.removeItem("ski");
    }

  }, [seatsAndLuggage, roomNumber, flightNumber])




  const handleUpdate = (type, operation) => {
    setSeatsAndLuggage(prev => {
      let updateData = { ...prev };
      if (operation === 'minus') {
        if (prev[type] > 0) {
          updateData[type] = prev[type] - 1;
        } else {
          return prev;
        }
      } else if (operation === 'plus') {
        if (prev[type] < 8) {
          updateData[type] = prev[type] + 1;
        } else {
          return prev;
        }
      }
      return updateData;
    });
  };

  const saveBookingData = ()=>{
    const data = {
      first_name:firstName,
      last_name:lastName,
      flight_num: flightNumber,
      room:roomNumber,
      seats_booked:seatsAndLuggage?.seats,
      no_of_bags:seatsAndLuggage?.luggage
    }

    localStorage.setItem("booking",JSON.stringify(data))
    window.localStorage.removeItem('dataBus')
  }


  useEffect(() => {
    axios.get('https://kiro-travel.herokuapp.com/api/v1/shuttle/ski-prices/')
    .then((res) => {
      console.log(res)
      setPriceSki(res.data[0])
    })
    .catch((err) => {
      console.log(err)
    })
  }, [])

  return (
    <>
      <Fade top>
        <div className='mt-5 d-flex justify-content-between align-items-center flex-wrap'>

          {
            localStorage.getItem("token") == null ? (
              (
                <div className='responsive-date d-flex justify-content-between align-items-center  w-100'>
                  <h3>{t('log_first')}</h3>
                  <div>
                    <Link to='/login' className='btn btn-warning'>{t('login')}</Link>
                    <Link to='/register' className='btn btn-warning mx-3'>{t('Register')}</Link>
                  </div>
                </div>

              )

            ) : (
              (
                x == '' || x?.length == 2 || x == null ? (
                  <>
                    <h2 className='m-auto text-center size'>
                      {t('bus')}
                    </h2>
                  </>
                ) : (
                  (
                    x.map((e, index) => (
                    <div key={index} className='parent_result'>
                    
                    <div>{}</div>
                      <div className='border_bottom'>
                        <div className='box_data'>

                          <div className="departure_arrive">
                            <h3>Departure from : {e.pickup_location === 'M3 Hôtel & Résidence Ferney Geneva Airport' ? "M3 Hôtel" : e.pickup_location}</h3>
                            <h2>{e.time}</h2>
                          </div>

                          <div className='bus-icon'>
                            <span className="icon"><MdOutlineDirectionsBusFilled size={30} /></span>
                            <span className='line_under_icon'></span>
                          </div>

                          <div className="departure_arrive_two">
                            <h3>Arrive to : {e.drop_location === 'M3 Hôtel & Résidence Ferney Geneva Airport' ? "M3 Hôtel" : e.drop_location}</h3>
                            {/* <h2>04:02</h2> */}
                          </div>

                          <div className='two_counter'>

                              <div className='border_counter'>
                                <span className='title_counter'>Luggage</span>
                                <span onClick={() => handleUpdate("luggage","minus")} className='minus'>-</span>
                                <span className='value_counter'>{seatsAndLuggage?.luggage}</span>
                                <span onClick={() => handleUpdate("luggage", "plus")} className='plus'>+</span>
                              </div>
                            
                              <div className='border_counter'>
                                <span className='title_counter'>Seats</span>
                                <span onClick={() => handleUpdate("seats","minus")} className='minus'>-</span>
                                <span className='value_counter'>{seatsAndLuggage?.seats}</span>
                                <span onClick={() => handleUpdate("seats", "plus")} className='plus'>+</span>
                              </div>

                              <div className='border_counter'>
                                <span className='title_counter'>Ski Matrial</span>
                                <button type='button' onClick={() => handleUpdate("ski","minus")} className='minus'>-</button>
                                <span className='value_counter'>{seatsAndLuggage?.ski}</span>
                                <button type='button' disabled={seatsAndLuggage.ski >= 4} onClick={() => handleUpdate("ski", "plus")} className='plus'>+</button>
                              </div>

                          </div>

                          <div className={`price_bus ${seatsAndLuggage.seats === 0 ? '' : ''}`}>
                          <h3>
                              {seatsAndLuggage && seatsAndLuggage.seats === 0 ? (
                                <span className='h6'>Enter Seats</span>
                              ) : seatsAndLuggage && seatsAndLuggage.seats === 1 ? (
                                seatsAndLuggage.ski === 1 ? e.shuttle.price_for_one_seat + +priceSki.price_for_one : seatsAndLuggage.ski === 2 ? e.shuttle.price_for_one_seat + +priceSki.price_for_two : seatsAndLuggage.ski === 3 ? e.shuttle.price_for_one_seat + +priceSki.price_for_three :seatsAndLuggage.ski === 4 ? e.shuttle.price_for_one_seat + +priceSki.price_for_four : e.shuttle.price_for_one_seat
                              ) : seatsAndLuggage && seatsAndLuggage.seats === 2 ? (
                                seatsAndLuggage.ski === 1 ? e.shuttle.price_for_two_seat + +priceSki.price_for_one : seatsAndLuggage.ski === 2 ? e.shuttle.price_for_two_seat + +priceSki.price_for_two : seatsAndLuggage.ski === 3 ? e.shuttle.price_for_two_seat + +priceSki.price_for_three :seatsAndLuggage.ski === 4 ? e.shuttle.price_for_two_seat + +priceSki.price_for_four : e.shuttle.price_for_two_seat
                              ) : seatsAndLuggage && seatsAndLuggage.seats === 3 ? (
                                seatsAndLuggage.ski === 1 ? e.shuttle.price_for_three_seat + +priceSki.price_for_one : seatsAndLuggage.ski === 2 ? e.shuttle.price_for_three_seat + +priceSki.price_for_two : seatsAndLuggage.ski === 3 ? e.shuttle.price_for_three_seat + +priceSki.price_for_three :seatsAndLuggage.ski === 4 ? e.shuttle.price_for_three_seat + +priceSki.price_for_four : e.shuttle.price_for_three_seat
                              ) : seatsAndLuggage && seatsAndLuggage.seats === 4 ? (
                                seatsAndLuggage.ski === 1 ? e.shuttle.price_for_four_seat + +priceSki.price_for_one : seatsAndLuggage.ski === 2 ? e.shuttle.price_for_four_seat + +priceSki.price_for_two : seatsAndLuggage.ski === 3 ? e.shuttle.price_for_four_seat + +priceSki.price_for_three :seatsAndLuggage.ski === 4 ? e.shuttle.price_for_four_seat + +priceSki.price_for_four : e.shuttle.price_for_four_seat
                              ) : seatsAndLuggage && seatsAndLuggage.seats === 5 ? (
                                seatsAndLuggage.ski === 1 ? e.shuttle.price_for_five_seat + +priceSki.price_for_one : (seatsAndLuggage.ski === 2 ? e.shuttle.price_for_five_seat + +priceSki.price_for_two : (seatsAndLuggage.ski === 3 ? e.shuttle.price_for_five_seat + +priceSki.price_for_three : (seatsAndLuggage.ski === 4 ? e.shuttle.price_for_five_seat + +priceSki.price_for_four : e.shuttle.price_for_five_seat)))
                              ) : seatsAndLuggage && seatsAndLuggage.seats === 6 ? (
                                seatsAndLuggage.ski === 1 ? e.shuttle.price_for_six_seat + +priceSki.price_for_one : (seatsAndLuggage.ski === 2 ? e.shuttle.price_for_six_seat + +priceSki.price_for_two : (seatsAndLuggage.ski === 3 ? e.shuttle.price_for_six_seat + +priceSki.price_for_three : (seatsAndLuggage.ski === 4 ? e.shuttle.price_for_six_seat + +priceSki.price_for_four : e.shuttle.price_for_six_seat)))
                              ) : seatsAndLuggage && seatsAndLuggage.seats === 7 ? (
                                seatsAndLuggage.ski === 1 ? e.shuttle.price_for_seven_seat + +priceSki.price_for_one : (seatsAndLuggage.ski === 2 ? e.shuttle.price_for_seven_seat + +priceSki.price_for_two : (seatsAndLuggage.ski === 3 ? e.shuttle.price_for_seven_seat + +priceSki.price_for_three : (seatsAndLuggage.ski === 4 ? e.shuttle.price_for_seven_seat + +priceSki.price_for_four : e.shuttle.price_for_seven_seat)))
                              ) : seatsAndLuggage && seatsAndLuggage.seats === 8 ? (
                                seatsAndLuggage.ski === 1 ? e.shuttle.price_for_eight_seat + +priceSki.price_for_one : (seatsAndLuggage.ski === 2 ? e.shuttle.price_for_eight_seat + +priceSki.price_for_two : (seatsAndLuggage.ski === 3 ? e.shuttle.price_for_eight_seat + +priceSki.price_for_three : (seatsAndLuggage.ski === 4 ? e.shuttle.price_for_eight_seat + +priceSki.price_for_four : e.shuttle.price_for_eight_seat)))
                              ) : null }
                              {" "}€
                            </h3>
                            <h2>Total Price</h2>
                          </div>

                        </div>
                        <div className='icons_counter'>
                          <div className="icons_prov">
                            <img src="date.png" alt="" />
                            <img src="Date & Time (1).png" alt="" />
                            <img src="Date & Time (2).png" alt="" />
                            <img src="Date & Time (3).png" alt="" />
                          </div>

                          {/* <div style={{display:'flex', gap:'10px'}}> */}
      
                            <button className='button_bus'
                              onClick={() => {
                                localStorage.setItem("busId", e.id)
                                userForm.id = e.id
                                userForm.user = localStorage.getItem('user_id')
                                localStorage.setItem("shuttle", e?.shuttle?.id)
                                setShowModal(true)
                              }}
                            >
                              Book Now
                            </button>
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                    ))
                  )
                )
              )

            )
          }

        </div>
        <div className='text-center d-flex btns-gap justify-content-center'>

          <Link to="/search" className='btn btn-primary mt-4' onClick={() => { setDepature('') }}>{t('Back')}</Link>
          {
            x == '' || latest?.length == 2  ? (<Link to="/TaxiSearch" className='mt-4 btn btn-danger'>{t('book_taxi')}</Link>) : ('')
          }

        </div>
        {/* Start Modal  */}
        <div className={`${showModal ? 'modal_custem' : 'modal_close'}`}>
          <div className="box_modal">
            <span onClick={() => setShowModal(!showModal)} className='close_w_icon'><MdClose size={23} /></span>
            {x?.length > 0 && (x[0].pickup_location === 'airport french side' || x[0].pickup_location === 'private flight' || x[0].pickup_location === 'airport suisse side') ? (
              <div>
                <div className='text-center mt-4'>
                  <AiOutlineNumber size={40} color='#999'/>
                </div>
                <input
                  type="text"
                  onChange={(e) => setFirstName(e.target.value) }
                  name="first_name"
                  className="w-100 p-2 mt-5"
                  placeholder={"First Name"}
                />
                <input
                  type="text"
                  onChange={(e) => setLastName(e.target.value) }
                  name="last_name"
                  className="w-100 p-2 mt-2"
                  placeholder={"Last Name"}
                />
                <input
                  type="text"
                  onChange={(e) => setFlightNumber(e.target.value) }
                  name="flight"
                  className="w-100 p-2 mt-2"
                  placeholder={t("Flight")}
                />

                <Button
                  disabled={(!flightNumber || !firstName || !lastName)}
                  onClick={() => {
                    saveBookingData()
                    form();
                  }}
                  className="warning w-100 mt-3"
                  variant="warning"
                >
                  {t("send")}{" "}
                </Button>

                <div className="text-danger text-center mt-3 h6">
                  {userError} {busError}
                </div>
              </div>
            ) : (
              <div>
                <div className='text-center mt-4'>
                  <AiOutlineNumber size={40} color='#999'/>
                </div>
                <input
                  type="text"
                  onChange={(e) => setFirstName(e.target.value) }
                  name="first_name"
                  className="w-100 p-2 mt-5"
                  placeholder={"First Name"}
                />
                <input
                  type="text"
                  onChange={(e) => setLastName(e.target.value) }
                  name="last_name"
                  className="w-100 p-2 mt-2"
                  placeholder={"Last Name"}
                />
                <input
                  type="text"
                  onChange={(e) => setRoomNumber(e.target.value)}
                  name="room"
                  className="w-100 p-2 mt-5"
                  placeholder={t("enter_room")}
                />

                <Button
                  disabled={(!roomNumber || !firstName || !lastName)}
                  onClick={() => {
                    saveBookingData()
                    form();
                  }}
                  className="warning w-100 mt-3"
                  variant="warning"
                >
                  {t("send")}{" "}
                </Button>

                <div className="text-danger text-center mt-3 h6">
                  {userError} {busError}
                </div>
              </div>
              
            )}
          </div>
        </div>
        {/* End Modal  */}
      </Fade>
    </>
  )
}
