import React, { useEffect } from "react";
import Modal from "react-modal";

function CustomModal({ children, isOpen, widthClass , handelToogel}) {
  useEffect(()=>{
    Modal.setAppElement('body')
  })
  const customStyles = {
    content: {
      background:"#FFF",
      borderRadius: "10px",
      position: "absolute",
      minWidth:"40%",
      maxWidth:"90%",
      width:"50%",
      padding:"41px 10px"
    }
  };
  return (
    <Modal isOpen={isOpen} style={customStyles}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      onRequestClose={()=> handelToogel()}
      className={`animate__animated animate__bounceIn`}
      shouldFocusAfterRender={false}
      preventScroll={false}
      
    >
        {children}
    </Modal>
  );
}

export default CustomModal;