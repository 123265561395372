/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { IoLogoWhatsapp } from "react-icons/io5";
import { BiPhoneCall } from "react-icons/bi";

function ContactsBtn() {
  return (
    <div className="parent_contacts_btn">
      <div className="d-flex flex-column justify-content-center align-items-center" style={{rowGap:"16px"}}>
        <a className="contact_btn"
            target="_blank" 
            href="https://wa.me/message/7GT22J3ROW33A1">
          <IoLogoWhatsapp size={25} color="#25D366" />
        </a>
        <a className="contact_btn" 
            target="_blank"
            href="tel://+33644915310">
          <BiPhoneCall size={25} color="#007bff"/>
        </a>
      </div>
    </div>
  );
}
export default ContactsBtn;