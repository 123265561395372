import React from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import { useTranslation} from "react-i18next";
import { Card } from 'react-bootstrap'
import {FiMail} from "react-icons/fi"
import {ImCancelCircle} from "react-icons/im"
import { IconContext } from 'react-icons'
import { useRef } from 'react';
import { Contextet } from '../Context/Context';
import {RiLockPasswordFill} from "react-icons/ri"
import { useContext } from 'react';

export default function Password() {
    let {t} = useTranslation()

    let { me3 , ConfirmPw , setUserNewPw , updatePw , setUserOldPw}=  useContext(Contextet)


  return (
    <div className='mt-5'>
         <Form.Group className="mb-3 d-flex flex-column" controlId="formBasicEmail">
         <Form.Control  type="password" placeholder="Enter your old password" className='mb-3'  onChange={(e)=>setUserOldPw(e.target.value)}/>
         <Form.Control  type="password" placeholder="Enter your new password"  onChange={(e)=>setUserNewPw(e.target.value)}/>
         <Button className='my-4' on onClick={updatePw}>{t('update_Pw')}</Button>
         <div ref={me3} id='updatePw' className='updateMail d-none'>
         <Card style={{ width: 'fit-content' }} className="p-3 m-auto">
       <Card.Body  className='text-center'>
         <Card.Title className='text-center text-danger'>{t('pwUpdate')}</Card.Title>
         <div className='my-3'>
         <IconContext.Provider value={{ size : "5em" , color : "red"}}>
         <RiLockPasswordFill/>
        </IconContext.Provider>
        </div>
        <h2 className='bold h5 '>{t('pwConfirm')}</h2>
       </Card.Body>
       <div className='text-center'>
          <Button className='btn btn-primary text-white mx-3' onClick={ConfirmPw}>{t('Yes')}</Button>
          <Button   className='btn btn-primary text-white' onClick={()=>document.getElementById('updatePw').classList.remove('d-flex')}>{t('No')}</Button>
 
          </div>
      </Card>
         </div>
       </Form.Group>
    </div>
  )
}
