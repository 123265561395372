import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { Contextet } from "../Context/Context";
import { useTranslation } from "react-i18next";
import "./Login.css";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { BiHide, BiShowAlt } from "react-icons/bi";

export default function Login() {
  const [showPass, setShowPass] = useState(true);

  let { log, getUser, user, checkDate, setCheckData } = useContext(Contextet);

  let { t } = useTranslation();

  useEffect(() => {
    return () => {
      setCheckData("");
    };
  }, []);

  return (
    <div className="login_LayOut">
      {localStorage.getItem("token") ? (
        <div className="text-center">
          <div className="text-danger h2">{t("credintial")}</div>
          <Link to="/" className="btn btn-primary mt-3">
            {t("home")}{" "}
          </Link>
        </div>
      ) : (
        <>
          <div className="parentImageInLogin">
            <img alt="taxi" src="/login.jpg" className="image_header" />
          </div>
          <Form.Group className="mb-4" controlId="formBasicEmail">
            {/* <Form.Label>{t('User_Name')} : </Form.Label> */}
            <div className="login_parent">
              <Form.Control
                className="inputLogin"
                onChange={getUser}
                type="email"
                placeholder="Enter User Name"
                name="username"
              />
              <div className="iconLogin">
                <MdOutlineAlternateEmail size={20} />
              </div>
            </div>
          </Form.Group>

          <Form.Group className="mb-4" controlId="formBasicPassword">
            {/* <Form.Label>{t('password')} :</Form.Label> */}
            <div className="login_parent">
              <Form.Control
                className="inputLogin"
                onChange={getUser}
                type={showPass ? "password" : "text"}
                placeholder="Password"
                name="password"
              />
              <div
                onClick={() => setShowPass((prev) => !prev)}
                className="iconLogin login_showPass"
              >
                {showPass ? <BiShowAlt size={20} /> : <BiHide size={20} />}{" "}
              </div>
            </div>
          </Form.Group>
          <div style={{margin:"3em 0.5em"}} className="d-flex justify-center gap">
            <Button
              className="btn-success"
              onClick={() => {
                log(user);
              }}
              variant="primary"
              type="submit"
            >
              {t("login")}
            </Button>
            <Link to="/register" className="btn btn-primary text-white">
              {" "}
              {t("Create_new_account")}{" "}
            </Link>
          </div>
        </>
      )}
      {checkDate ? (
        <div className="text-center text-danger h3">{checkDate}</div>
      ) : (
        ""
      )}
    </div>
  );
}
