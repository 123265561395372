/* eslint-disable eqeqeq */
import React, { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Contextet } from "./Context";

export let Registert = createContext();

// register your account

export function RegisterContexts({ children }) {
  let [value, setValue] = useState("");
  let [newUser, setNewUser] = useState({
    email: "",
    phone: "",
    password: "",
    username: "",
    hotel_name: "",
  });
  let [error, setError] = useState("");
  let { log } = useContext(Contextet);

  let phoneRegex =
    /^\+?([87](?!95[4-79]|99[08]|907|94[^0]|336|986)([348]\d|9[0-6789]|7[0247])\d{8}|[1246]\d{9,13}|68\d{7}|5[1-46-9]\d{8,12}|55[1-9]\d{9}|55[12]19\d{8}|500[56]\d{4}|5016\d{6}|5068\d{7}|502[45]\d{7}|5037\d{7}|50[4567]\d{8}|50855\d{4}|509[34]\d{7}|376\d{6}|855\d{8}|856\d{10}|85[0-4789]\d{8,10}|8[68]\d{10,11}|8[14]\d{10}|82\d{9,10}|852\d{8}|90\d{10}|96(0[79]|17[01]|13)\d{6}|96[23]\d{9}|964\d{10}|96(5[69]|89)\d{7}|96(65|77)\d{8}|92[023]\d{9}|91[1879]\d{9}|9[34]7\d{8}|959\d{7}|989\d{9}|97\d{8,12}|99[^4568]\d{7,11}|994\d{9}|9955\d{8}|996[57]\d{8}|9989\d{8}|380[3-79]\d{8}|381\d{9}|385\d{8,9}|375[234]\d{8}|372\d{7,8}|37[0-4]\d{8}|37[6-9]\d{7,11}|30[69]\d{9}|34[67]\d{8}|3[12359]\d{8,12}|36\d{9}|38[1679]\d{8}|382\d{8,9}|46719\d{10})$/;

  let navigate = useNavigate();
  function handleClick() {
    navigate("/");
  }
  function getNewUser({ target }) {
    let myUsers = { ...newUser };

    myUsers[target.name] = target.value;

    setNewUser(myUsers);
  }
  async function logs() {
    var formdata = new FormData();
    formdata.append("email", newUser.email);
    formdata.append("phone", value);
    formdata.append("password", newUser.password);
    formdata.append("username", newUser.username);
    formdata.append("hotel_name", newUser.hotel_name);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    if (
      newUser.email != "" &&
      newUser.hotel_name != "" &&
      newUser.password != "" &&
      value != "" &&
      newUser.username != "" &&
      phoneRegex.test(value) == true
    ) {
      await fetch(
        "https://kiro-travel.herokuapp.com/api/v1/users/register/",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          if (result.includes(`"success":true`)) {
            handleClick();
            setError("");
            localStorage.setItem("username", newUser.username);
            localStorage.setItem("password", newUser.password);
            var logMeIn = {
              username: localStorage.getItem("username"),
              password: localStorage.getItem("password"),
            };
            log(logMeIn);
          } else {
            setError(result);
          }
        })
        .catch((error) => {
          if (error) {
            setError("sorry we are not available for now kindly try later on");
          }
        });
    } else if (phoneRegex.test(value) == false) {
      setError("kindly enter a Valid Number");
    } else {
      setError("kindly enter all required information");
    }
  }

  return (
    <Registert.Provider value={{ logs, getNewUser, error, value, setValue }}>
      {children}
    </Registert.Provider>
  );
}
