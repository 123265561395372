/* eslint-disable eqeqeq */
import React, { createContext , useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";

export let TaxiUse = createContext();

// Taxi Api

export function TaxiContext({ children }) {
  let [taxi, setTaxi] = useState("");
  let [visible, setVisible] = useState(false);
  let [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  let { t } = useTranslation();
  function handle() {
    navigate("/taxi");
  }
  let [drop, setDrop] = useState("");
  let [pick, setPick] = useState("");
  let [me, setMe] = useState("");
  let y = pick.replace(/&/gi, "%26");
  let x = drop.replace(/&/gi, "%26");
  let [date, setDate] = useState(null);
  let [timing, setTiming] = useState(null);
  let [Note, setNote] = useState(null);
  let [seats, setSeats] = useState(null);

  async function taxiData(call) {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    if (drop != "" && pick != "") {
      await fetch(
        `https://kiro-travel.herokuapp.com/api/v1/shuttle/taxi_list/?pickup=${y}&drop=${x}`,
        requestOptions
      )
        .catch((error) => {
          if (error) {
            setMe("sorry we are not available for now try later on");
            setLoading(false);
          }
        })
        .then((response) => response.text())
        .then((result) => {
          setLoading(false);
          if (localStorage.getItem("token") != null) {
            localStorage.setItem("Taxi-result", result);
            handle();
          }
        });
      setCheck("");
    } else if (pick == "") {
      setMe(t("pick_up_error"));
      setLoading(false);
    } else if (drop == "") {
      setMe(t("dest_error"));
      setLoading(false);
    }
  }

  let [s, setS] = useState();
  let [check, setCheck] = useState();
  let [first, setFirst] = useState(null);

  function getTaxi(e) {
    let myUsers = s;
    myUsers = e.target.value;
    localStorage.setItem("taxi-type", myUsers);
    let x = localStorage.getItem("taxi-type");
    setS(x);
  }
  function visa(
    taxi,
    no_of_vechiles,
    backpack,
    handbag,
    big_luggage,
    flight_number,
    enfant,
    child,
    adult,
    room_number
  ) {
    setLoading(true);
    if (localStorage.getItem("taxi-type") == null) {
      setLoading(false);
      setCheck(t("van"));
    } else if (first == null) {
      setLoading(false);
      setCheck(t("enter_name"));
      window.scrollTo(0, 0);
    } else if (date == null) {
      setLoading(false);
      setCheck(t("date"));
      window.scrollTo(0, 0);
    } else if (timing == null) {
      setLoading(false);
      setCheck(t("time"));
      window.scrollTo(0, 0);
    } else {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("token")}`
      );
      var formdata = new FormData();
      formdata.append("vechile", localStorage.getItem("taxi-type"));
      formdata.append("user", localStorage.getItem("user_id"));
      formdata.append("taxi", taxi);
      formdata.append("backpack", backpack);
      formdata.append("handbag", handbag);
      formdata.append("big_luggage", big_luggage);
      formdata.append("flight_number", flight_number);
      formdata.append("enfant", enfant);
      formdata.append("child", child);
      formdata.append("adult", adult);
      formdata.append("room_number", room_number);
      formdata.append("full_name", first);
      formdata.append("date", date);
      formdata.append("time", timing);
      formdata.append("payment_method", "Visa");
      formdata.append(
        "ski_material",
        localStorage.getItem("ski") != null
          ? localStorage.getItem("ski")
          : "false"
      );

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      fetch(
        "https://kiro-travel.herokuapp.com/api/v1/shuttle/taxi_book/",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          setLoading(false);
          if (result.message == "Taxi Reservation created successfully.") {
            // navigate('/thanks')
            window.location.replace(result.data.payment_link);
          }
        })
        .catch((error) => {
          if (error) {
            setCheck(t("sorry"));
            window.scrollTo(0, 0);
            setLoading(false);
          }
        });
      setVisible(true);
      setCheck("");
      setVisible(false);
      localStorage.removeItem("taxi-type");
    }
  }

  function sendInfo(
    taxi,
    no_of_vechiles,
    backpack,
    handbag,
    big_luggage,
    flight_number,
    enfant,
    child,
    adult,
    room_number
  ) {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );
    if (localStorage.getItem("taxi-type") == null) {
      setLoading(false);
      setCheck(t("van"));
      window.scrollTo(0, 0);
    } else if (first == null) {
      setLoading(false);
      setCheck(t("enter_name"));
      window.scrollTo(0, 0);
    } else if (date == null) {
      setLoading(false);
      setCheck(t("date"));
      window.scrollTo(0, 0);
    } else if (timing == null) {
      setLoading(false);
      setCheck(t("time"));
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
      var formdata = new FormData();
      formdata.append("vechile", localStorage.getItem("taxi-type"));
      formdata.append("user", localStorage.getItem("user_id"));
      formdata.append("taxi", taxi);
      formdata.append("backpack", backpack);
      formdata.append("handbag", handbag);
      formdata.append("big_luggage", big_luggage);
      formdata.append("flight_number", flight_number);
      formdata.append("enfant", enfant);
      formdata.append("child", child);
      formdata.append("adult", adult);
      formdata.append("room_number", room_number);
      formdata.append("full_name", first);
      formdata.append("date", date);
      formdata.append("time", timing);
      formdata.append("payment_method", "Cash");
      formdata.append(
        "ski_material",
        localStorage.getItem("ski") != null
          ? localStorage.getItem("ski")
          : "false"
      );
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      fetch(
        "https://kiro-travel.herokuapp.com/api/v1/shuttle/taxi_book/",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.message == "Taxi Reservation created successfully.") {
            setLoading(false);
            navigate("/Thanks");
            setCheck("");
          } else {
            setCheck("Kindly enter required field");
            window.scrollTo(0, 0);
            localStorage.removeItem("taxi-type");
            setLoading(false);
          }
        })
        .catch((err) => {
          toast.error(t("serverError"));
          setLoading(false);
        });
    }
  }

  // send request

  function requesting() {
    setLoading(true);
    if (pick == "") {
      setLoading(false);
      setMe(t("pick_up_error"));
    } else if (drop == "") {
      setLoading(false);
      setMe(t("dest_error"));
    } else if (seats == null) {
      setLoading(false);
      setMe("enter_seats");
    } else if (date == null) {
      setLoading(false);
      setMe(t("date_error"));
    } else if (timing == null) {
      setLoading(false);
      setMe(t("time"));
    } else {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("token")}`
      );
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("pickup_location", y);
      urlencoded.append("drop_location", x);
      urlencoded.append("date", date);
      urlencoded.append("time", timing);
      urlencoded.append("message", Note);
      urlencoded.append("seats", seats);
      urlencoded.append("user", localStorage.getItem("user_id"));

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      };
      fetch(
        "https://kiro-travel.herokuapp.com/api/v1/shuttle/ride_request/",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          if (result.includes(localStorage.getItem("user_id"))) {
            navigate("/thanks");
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          toast.error(t("serverError"));
        });
    }
  }

  return (
    <TaxiUse.Provider
      value={{
        loading,
        setSeats,
        setCheck,
        setFirst,
        requesting,
        me,
        setMe,
        pick,
        setPick,
        drop,
        setDrop,
        Note,
        setNote,
        timing,
        setTiming,
        date,
        setDate,
        taxi,
        taxiData,
        visa,
        setTaxi,
        sendInfo,
        setS,
        s,
        check,
        getTaxi,
        visible,
      }}
    >
      {children}
    </TaxiUse.Provider>
  );
}
