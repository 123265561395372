/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { BsTwitter, BsFacebook, BsInstagram } from "react-icons/bs";
import { FaTumblr } from "react-icons/fa";
import { IconContext } from "react-icons";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Footer() {
  let { t } = useTranslation();
  return (
    <div className="text-center mt-3">
      <Fade left>
        <div className="icons p-2">
          <a
            href="https://twitter.com/KIROLIMOVTCTAXI"
            target="_blank"
            title="Twitter"
            className="px-3"
          >
            <IconContext.Provider value={{ color: "#1da1f2", size: "2em" }}>
              <BsTwitter className="insta" />
            </IconContext.Provider>
          </a>
          <a
            href="https://www.instagram.com/kirotravelpaysdegex/"
            target="_blank"
            title="Instagram"
            className="px-3"
          >
            <IconContext.Provider value={{ color: "#f1634b", size: "2em" }}>
              <BsInstagram className="insta" />
            </IconContext.Provider>
          </a>
          <a
            href="https://www.facebook.com/kirolimovtctaxi"
            target="_blank"
            title="Facebook"
            className="px-3"
          >
            <IconContext.Provider value={{ color: "blue", size: "2em" }}>
              <BsFacebook className="social fb" />
            </IconContext.Provider>
          </a>
          <a
            href="http://kirolimo-kirotrvel-paysdegex.tumblr.com"
            target="_blank"
            title="Tumblr"
            className="px-3"
          >
            <IconContext.Provider
              className="p-5"
              value={{
                color: "white",
                size: "2em",
                backgroundColor: "#314359",
              }}
            >
              <FaTumblr className="social tum" />
            </IconContext.Provider>
          </a>
        </div>
        <div className="policy py-2">
          <Link to="/Conditions" className="margin policy">
            {t("condtions")}
          </Link>
          <Link to="/Privacy" className="policy">
            {t("Privacy_policy")}
          </Link>
        </div>
      </Fade>
    </div>
  );
}
