import React from 'react'
import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import {TbCreditCardOff} from "react-icons/tb"
import {BsCreditCard2Front} from "react-icons/bs"
import { IconContext } from 'react-icons'
import { useTranslation} from "react-i18next";





export default function Payment() {
let {t} = useTranslation()
  return (
    <div className='text-center mt-5'>
     <Card style={{ width: 'fit-content' }} className="p-3 m-auto">
      <Card.Body >
        <Card.Title className='text-center text-success'>{t('success')}</Card.Title>
        <IconContext.Provider value={{ size : "5em" , color : "#28a745"}}>
        <BsCreditCard2Front/>
       </IconContext.Provider>
       <h2 className='bold h5 '>{t('payment_approval')}</h2>
        <p className='m-0 p-0'>{t('check_email')}</p>
      </Card.Body>
      <div className='text-center'>
         <Link to='/thanks' className='btn btn-primary text-white'>{t('cont')}</Link>
         </div>
    </Card>
      
    </div>
  )
}
