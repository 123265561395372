import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import { HashRouter } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import ContactsBtn from "./Components/ContactsBtn";
import 'animate.css';
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ["en", "fr"],
    fallbackLng: "en",
    detection: {
      order: ["htmlTag", "cookie", "subdomain", "localStorage", "path"],
      caches: ["cookie"],
    },

    backend: {
      loadPath: "assests/localiz/{{lng}}/translation.json",
    },

    react: { useSuspense: false },

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HashRouter>
    <ContactsBtn />
    <App />
  </HashRouter>
);
