import React from 'react'
import { useEffect } from 'react';
import Footer from './Footer'
import { useTranslation} from "react-i18next";

export default function Condtions() {

  function myFunction() {
    window.scrollTo(0, 0);
  }

  let {t} = useTranslation()


  useEffect(() => {
    myFunction()
  }, [])
  


  return (
    <div className='mt-3 design'>
    <h2 className='h1 text-center mb-5 text-danger'>{t('condtions_terms')}</h2>
    <p><span className='text-primary'>www.kirotravel.com</span>  {t('publish')}</p>
    <p><strong>SASU</strong> {t('sasu')}</p>
    <p>{t('registration')}</p>
    <p><strong>{t('vat')}</strong>  :  FR69851536441</p>
    <p><strong>{t('Address')}</strong>: 01630 Saint Genis Pouilly, France</p>
    <p><strong>{t('Phone_Number')} </strong>: 0644915310</p>
    <p><strong>{t('Email_Address')}</strong>: contact@kirolimo.fr</p>
    <p><strong>{t('manger')}</strong>: contact@kirolimo.fr</p>
    <p>{t('release')}</p>
    <h2 className='h1'>{t('GDPR_contiton')}</h2>
    <p>{t('invitation')}</p>
    <p>- {t('privacy')}</p>
    <p>{t('modify')}<span className='text-primary'>contact@kirolimo.fr</span></p>
    <h2 className='h1'>{t('services')}</h2>
    <p>{t('reasons')}</p>
    <p>{t('owner_strive')} <span className='text-primary'> www.kirotravel.com</span>{t('held')}</p>
    <p>{t('responsible')}.</p>
    <p>{t('All_info')}<span className='text-primary'>www.kirotravel.com </span> {t('clarifacation')}</p>
    <h2 className='h1'>{t('Intellectual')}</h2>
    <p>{t('hold')}</p>
    <p>{t('publication')}<span className='text-primary'> contact@kirolimo.fr.</span> </p>
    <p>{t('unatucth')}</p>
    <h2 className='h1'>Hypertext links and cookies:</h2>
    <p><span className='text-primary'>www.kirotravel.com</span>{t('contatins')}</p>
    <p>{t('visits')}<span className='text-primary'>www.kirotravel.com</span> {t('attendence')}</p>
    <p>{t('configration')} <span className='text-primary'>www.cnil.fr</span></p>
    <p>{t('refusal')}</p>
    <p>{t('browser')}</p>
    <p>{t('netscape')}</p>
    <h2 className='h1'>{t('protect')}</h2>
    <p>{t('using')} <span className='text-primary'>www.kirotravel.com</span></p>
    <p>{t('law_personal')}</p>
    <p>{t('on_site')}<span className='text-primary'>www.kirotravel.com</span> {t('ours')}<span className='text-primary'>www.kirotravel.com</span>{t('continue')} <span className='text-primary'>www.kirotravel.com</span> {t('obligation')}</p>
    <p>{t('accordance')}<span className='text-primary'>www.kirotravel.com</span> {t('by_email"')} <span className='text-primary'>contact@kirolimo.fr</span> {t('signed_making')}</p>
    <p>{t('how_use')}</p>
    <p> {t('site')} <span className='text-primary'>www.kirotravel.com</span> {t('decleration')}</p>
     <p>{t('database')}</p>
     <Footer />
    </div>
  )
}
