/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
/* eslint-disable eqeqeq */
import React, { createContext, useContext, useState } from "react";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { TaxiUse } from "./TaxiContext";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ImSpinner8 } from "react-icons/im";
import Url from "../Components/Baseurl";
import { useRef } from "react";
import { toast, Toaster } from "react-hot-toast";

export let Contextet = createContext();

export function Contexts({ children }) {
  let [user, setuser] = useState({ username: "", password: "" });
  let [change, setChange] = useState(true);
  let { taxiData, setTaxi } = useContext(TaxiUse);
  let [visible, setVisible] = useState(false);
  let [userNewMail, setUserNewMail] = useState(null);
  let [userNewPw, setUserNewPw] = useState(null);
  let [pastTrips, setPastTrips] = useState(null);
  let [userHotel, setUserHotel] = useState(null);
  let [userNewPhone, setUserNewPhone] = useState(null);

  let test2 = document.getElementById("test2");
  let updatePwNew = document.getElementById("updatePw");
  let Hotel = document.getElementById("Hotel");

  var me2 = useRef();
  var me3 = useRef();
  let { t } = useTranslation();
  let navigate = useNavigate();
  function handleClick() {
    navigate("/");
  }

  // form
  let [userForm, setUserForm] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    no_of_bags: "",
    id: localStorage.getItem("busId"),
    seats_booked: "",
    ski:"",
    user: localStorage.getItem("user_id"),
    room: "",
    flight: "",
  });

  let k = useNavigate();

  let [busError, setBusError] = useState("");
  let [userError, setUserBusError] = useState("");
  let [customerID, setCustomerID] = useState(null);
  let [price, setPrice] = useState(null);
  let [allTrips, setAlltrips] = useState(null);
  let [AllUpcomingtrips, setAllUpcomingtrips] = useState(null);
  let [userOldPw, setUserOldPw] = useState(null);

  async function form() {
    // console.log(userForm)
    // console.log(localStorage.getItem("busId"))
    if (localStorage.getItem("busId") == null) {
      setBusError(t("choose_bus"));
      setTimeout(() => {
        k("/bus");
      }, 1500);
    } else {
      // console.log('else')
      if (userForm.first_name == "" || userForm.last_name == "") {
        setUserBusError(t("enter_name"));
      } else if (userForm.seats_booked == "" || userForm.seats_booked == "e") {
        setUserBusError(t("enter_seats"));
      } else if (userForm.phone == "" || userForm.phone == "e") {
        setUserBusError(t("enter_phone"));
      } else if (userForm.id == null) {
        setUserBusError(t("try_later"));
      } else if (userForm.user == null) {
        setUserBusError(t("try_later"));
      } 
      // else if (
      //   pick != localStorage.getItem("hotel") &&
      //   !userForm.flight == true
      // ) {
      //   setUserBusError(t("enter_flight"));
      // } 
      else if (
        localStorage.getItem("hotel") !=
        "M3 Hôtel & Résidence Ferney Geneva Airport"
      ) {
        let myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `Bearer ${localStorage.getItem("token")}`
        );
        let formdata = new FormData();
        formdata.append("first_name", userForm.first_name);
        formdata.append("last_name", userForm.last_name);
        formdata.append("seats_booked", userForm.seats_booked);
        formdata.append("trip", localStorage.getItem("busId"));
        formdata.append("no_of_bags", userForm.no_of_bags);
        formdata.append("phone", userForm.phone);
        formdata.append("user", userForm.user);
        formdata.append("room_number", userForm.room);
        formdata.append(
          "ski_material",
          localStorage.getItem("ski") != null
            ? localStorage.getItem("ski")
            : "false"
        );

        let requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: formdata,
          redirect: "follow",
        };

        fetch(
          "https://kiro-travel.herokuapp.com/api/v1/shuttle/book/",
          requestOptions
        )
          .then((response) => response.text())
          .then((result) => {
            let x = JSON.parse(result).data.reservation_id;
            if (x != "" || x != null) {
              setCustomerID(x);
            }
          });
        setVisible(true);
        k("/thanks");
        localStorage.removeItem("busId");
      } else if (
        localStorage.getItem("hotel") ==
        "M3 Hôtel & Résidence Ferney Geneva Airport"
      ) {
        let myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `Bearer ${localStorage.getItem("token")}`
        );
        var formdata = new FormData();
        formdata.append("ski", userForm.ski);
        formdata.append("seats_reserved", userForm.seats_booked);
        formdata.append("user", userForm.user);
        formdata.append("ride", localStorage.getItem("busId"));
        formdata.append("shuttle", localStorage.getItem("shuttle"));
        formdata.append("flight_number", userForm.flight);
        formdata.append("room_number", userForm.room);
        formdata.append('ski_material', +userForm.ski > 0 ? true : false)

        let requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: formdata,
          redirect: "follow",
        };
        await fetch(
          `https://kiro-travel.herokuapp.com/api/v1/shuttle/ride/`,
          requestOptions
        )
          .then((response) => response.text())
          .then((res) => {
            let result = JSON.parse(res);
            if(Array.isArray(result.ski)) {
              if(result.ski[0] == "All ski space reserved") {
                toast.error('All ski space reserved')
              }
            } else {
              // The monetary value is Price sent to storage, but the name is different for security.
              localStorage.setItem("car_id", result?.price);
              setPrice(result?.price);
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
      if (localStorage.getItem("car_id") != null) {
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `Bearer ${localStorage.getItem("token")}`
        );
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        var urlencoded = new URLSearchParams();
        urlencoded.append("amount", localStorage.getItem("car_id"));
        urlencoded.append("pickup", y);
        urlencoded.append("drop", x);
        urlencoded.append("username", localStorage.getItem("username"));

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: urlencoded,
          redirect: "follow",
        };

        fetch(
          "https://kiro-travel.herokuapp.com/api/v1/shuttle/ride-checkout/",
          requestOptions
        )
          .then((response) => response.text())
          .then((result) => {
            let x = JSON.parse(result);
            localStorage.setItem("status", x.id);
            window.location.replace(`${x.url}`);
            setUserBusError(t("booking_confirm"));
          })
          .catch((error) => {
            console.log("error", error);
          })
          .finally(() => localStorage.removeItem('car_id'))
      }
    }
  }
  // end of form

  let [checkDate, setCheckData] = useState();

  var mailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  async function log(data) {
    var x = await axios
      .post("https://kiro-travel.herokuapp.com/api/v1/users/login/", data)
      .catch(function (error) {
        if (error.response == 500 || error.response == 503) {
          setCheckData(t("sorry"));
        } else {
          setCheckData(error.response.data.detail);
        }
      });

    if (user.username == "") {
      setCheckData(t("enter_user"));
    } else if (user.password == "") {
      setCheckData(t("enter_pass"));
    }
    // console.log("res", x.data.data.access);
    // if(x.data.message == "Login successful."){
    if (x.data.success) {
      localStorage.setItem("token", x.data.data.access);
      localStorage.setItem("userEmail", x.data.data.email);
      localStorage.setItem("phone", x.data.data.phone);
      localStorage.setItem("taxi", x.data.data.taxi);
      localStorage.setItem("shuttleShow", x.data.data.shuttle);
      let encodedToken = localStorage.getItem("token");
      let useData = jwtDecode(encodedToken);
      localStorage.setItem("hotel", x.data.data.hotel_name);
      localStorage.setItem("user_id", useData.user_id);
      localStorage.setItem("username", x.data.data.username);
      navigate("/");
      setCheckData("");
      setuser({ username: "", password: "" });
      setChange(false);
      let r = setInterval(() => {
        if (localStorage.getItem("token") != null) {
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
          var urlencoded = new URLSearchParams();
          urlencoded.append("refresh", localStorage.getItem("refresh-token"));
          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
            redirect: "follow",
          };

          fetch(
            "https://kiro-travel.herokuapp.com/api/v1/users/refresh/",
            requestOptions
          )
            .then((response) => response.text())
            .then((result) => {
              let i = result;
              let x = i.substring(11);
              let o = x.slice(0, -2);
              localStorage.removeItem("token");
              localStorage.setItem("token", o);
            });
        } else {
          clearTimeout(r);
        }
      }, 1296000000);
    }
    setTimeout(() => {
      localStorage.clear();
    }, 691200000);
  }

  async function logAdmin(data) {
    let x = await axios
      .post("https://kiro-travel.herokuapp.com/api/v1/users/login/", data)
      .catch(function (error) {
        if (error.response) {
          setCheckData(t("sorry"));
        }
      });
    if (user.username == "") {
      setCheckData("kindly enter your username");
    } else if (user.password == "") {
      setCheckData("kindly enter your password");
    } else if (x.data.message == "User logged in successfully") {
      localStorage.setItem("token", x.data.data.access);
      localStorage.setItem("refresh-token", x.data.data.refresh);
      let encodedToken = localStorage.getItem("token");
      let useData = jwtDecode(encodedToken);
      localStorage.setItem("hotel", x.data.data.hotel_name);
      localStorage.setItem("user_id", useData.user_id);
      localStorage.setItem("username", x.data.data.username);
      navigate("/adminpage");

      let r = setInterval(() => {
        if (localStorage.getItem("token") != null) {
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

          var urlencoded = new URLSearchParams();
          urlencoded.append("refresh", localStorage.getItem("refresh-token"));

          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
            redirect: "follow",
          };

          fetch(
            "https://kiro-travel.herokuapp.com/api/v1/users/refresh/",
            requestOptions
          )
            .then((response) => response.text())
            .then((result) => {
              let i = result;
              let x = i.substring(11);
              let o = x.slice(0, -2);
              localStorage.removeItem("token");
              localStorage.setItem("token", o);
            });
        } else {
          clearTimeout(r);
        }
      }, 1296000000);
    }

    setTimeout(() => {
      localStorage.clear();
    }, 691200000);
  }

  let [depature, setDepature] = useState(null);
  let [compelete, setCompelete] = useState(null);
  let [review, setReview] = useState(null);
  let [page, setPage] = useState(null);
  let [prvPgae, setPrvpage] = useState(null);
  async function reviews() {
    let x = await axios.get(
      "https://kiro-travel.herokuapp.com/api/v1/reviews/"
    );
    if (x.status == 200) {
      setReview(x.data.results);
      setPage(x.data.next);
    }
  }

  async function reviewsNe(url) {
    let x = await axios.get(`${url}`);
    if (x.status == 200) {
      setReview(x.data.results);
      setPage(x.data.next);
      setPrvpage(x.data.previous);
    }
  }

  function http() {
    if (window.location.href.includes("http://booking.kirotravel.com")) {
      window.location.replace("https://booking.kirotravel.com/#/");
    }
  }

  useEffect(() => {
    reviews();
    http();
  }, []);

  function getUser({ target }) {
    let myUser = { ...user };

    myUser[target.name] = target.value;

    setuser(myUser);
  }

  let [note, setNote] = useState({
    name: "",
    comment: "",
    review: "",
    user: localStorage.getItem("user_id"),
    room: "",
  });
  let [notError, setNoteError] = useState("");
  function getreview({ target }) {
    let myUser = { ...note };

    myUser[target.name] = target.value;

    setNote(myUser);
  }

  function myComment() {
    if (
      note.name != "" &&
      note.comment != "" &&
      note.review <= 10 &&
      note.room != ""
    ) {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("token")}`
      );
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("name", note.name);
      urlencoded.append("review", note.comment);
      urlencoded.append("rating", note.review);
      urlencoded.append("user", localStorage.getItem("user_id"));
      urlencoded.append("room_number", note.room);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      };
      fetch(
        "https://kiro-travel.herokuapp.com/api/v1/reviews/",
        requestOptions
      ).then((response) => response.text());
      setNoteError(`Thank you we got your response`);
      setTimeout(() => {
        handleClick();
      }, 500);
    } else if (note.review > 10) {
      setNoteError(t("rate"));
    } else {
      setNoteError(t("All"));
    }
  }

  let [drop, setDrop] = useState("");
  let [me, setMe] = useState("");
  let [pick, setPick] = useState("");
  let [latest, setLatest] = useState(null);
  let [date, setDate] = useState(null);

  var y = pick.replace(/&/gi, "%26");
  var x = drop.replace(/&/gi, "%26");

  async function dropMe() {
    if (drop != "" && pick != "" && date != null) {
      setMe("");
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      await fetch(
        `https://kiro-travel.herokuapp.com/api/v1/shuttle/trips/?pickup=${y}&drop=${x}&date=${date}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.data.length == 0) {
            setLatest("");
          } else {

            setLatest(result.data.sort((a, b) => {
                return a.time.localeCompare(b.time);  
              }))
            // setLatest(result?.data);
          }
        });
      k("/bus");
      setPick("");
      setDrop("");
    } else if (pick == "" || pick == "Choose your pick up") {
      setMe(t("pick_up_error"));
    } else if (drop == "" || drop == "Choose your destination") {
      setMe(t("dest_error"));
    } else if (date == null) {
      setMe(t("date_error"));
    }
  }

  function changeMail(e) {
    setUserNewMail(e.target.value);
  }

  function updateMail() {
    if (
      userNewMail == localStorage.getItem("userEmail") ||
      userNewMail == null
    ) {
      toast.error(t("mailErrorSame"));
    } else if (!userNewMail == true) {
      toast.error(t("mailErrorInput"));
    } else if (mailRegex.test(userNewMail) == false) {
      toast.error(t("mailRegexError"));
    } else {
      test2.classList.add("d-flex");
    }
  }
  function updatePhone() {
    if (userNewPhone == null || !userNewPhone == true) {
      toast.error(t("phoneEmptyError"));
    } else {
      document.getElementById("updatePhone").classList.add("d-flex");
    }
  }
  function updatePw() {
    if (
      !userNewPw == true ||
      userNewPw == null ||
      userOldPw == null ||
      !userOldPw == true
    ) {
      toast.error(t("pwEmtyError"));
    } else {
      updatePwNew.classList.add("d-flex");
    }
  }

  function updateHotel() {
    if (userHotel == null) {
      toast.error(t("hotelErrorSame"));
    } else {
      Hotel.classList.add("d-flex");
    }
  }

  async function ConfirmMail() {
    let res = await axios.patch(
      `https://kiro-travel.herokuapp.com/api/v1/users/update-email/`,
      {
        email: userNewMail,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    let data = res;

    if (data.status == 200) {
      toast.success(data.data.success);
      localStorage.setItem("userEmail", userNewMail);
      setUserNewMail(null);
      test2.classList.remove("d-flex");
    } else {
      toast.error(t("serverError"));
    }
  }

  async function ConfirmPw() {
    let res = await axios
      .patch(
        `https://kiro-travel.herokuapp.com/api/v1/users/update-password/`,
        {
          password: userNewPw,
          old_password: userOldPw,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .catch((e) => {
        if (e.response.status == 406) {
          toast.error(
            `${e.response.data.error} kindly enter the right password`
          );
          updatePwNew.classList.remove("d-flex");
        }
      });
    let data = res;

    if (data.status == 200) {
      toast.success(data.data.success);
      setUserNewPw(null);
      updatePwNew.classList.remove("d-flex");
      handleClick();
    } else {
      toast.error(data.data.error);
    }
  }

  async function ConfirmHotel() {
    let res = await axios.patch(
      `https://kiro-travel.herokuapp.com/api/v1/users/update-hotel-name/`,
      {
        hotel_name: userHotel,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    let data = res;

    if (data.status == 200) {
      toast.success(data.data.success);
      localStorage.setItem("hotel", userHotel);
      setUserHotel(null);
      Hotel.classList.remove("d-flex");
    } else {
      toast.error(t("serverError"));
    }
  }
  async function ConfirmPhone() {
    let res = await axios.patch(
      `https://kiro-travel.herokuapp.com/api/v1/users/update-phone/`,
      {
        phone: userNewPhone,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    let data = res;

    if (data.status == 200) {
      toast.success(data.data.success);
      setUserNewPhone(null);
      document.getElementById("updatePhone").classList.remove("d-flex");
      localStorage.setItem("phone", userNewPhone);
    } else {
      toast.error(t("serverError"));
    }
  }
  async function getAllTrips(e) {
    let res = await axios.get(
      `https://kiro-travel.herokuapp.com/api/v1/shuttle/upcoming_trips/?page=${e}&when=all`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    let data = res;

    if (data.status == 200) {
      setAlltrips(data.data);
    }
  }
  async function getUpcomingTrips(e) {
    let res = await axios.get(
      `https://kiro-travel.herokuapp.com/api/v1/shuttle/upcoming_trips/?page=${e}&when=upcoming`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    let data = res;

    if (data.status == 200) {
      setAllUpcomingtrips(data.data);
    }
  }

  async function getPastTrips(e) {
    let res = await axios.get(
      `https://kiro-travel.herokuapp.com/api/v1/shuttle/upcoming_trips/?page=${e}&when=History`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    let data = res;

    if (data.status == 200) {
      setPastTrips(data.data);
    }
  }

  return (
    <Contextet.Provider
      value={{
        setUserOldPw,
        ConfirmPhone,
        updatePhone,
        setUserNewPhone,
        ConfirmHotel,
        updateHotel,
        setUserHotel,
        pastTrips,
        getPastTrips,
        AllUpcomingtrips,
        getUpcomingTrips,
        getAllTrips,
        allTrips,
        ConfirmPw,
        updatePw,
        setUserNewPw,
        ConfirmMail,
        updateMail,
        changeMail,
        me2,
        me3,
        setCustomerID,
        setNoteError,
        busError,
        logAdmin,
        prvPgae,
        page,
        reviewsNe,
        setCheckData,
        checkDate,
        setMe,
        customerID,
        setDate,
        date,
        latest,
        compelete,
        setCompelete,
        userError,
        setBusError,
        pick,
        setPick,
        dropMe,
        drop,
        setDrop,
        me,
        notError,
        myComment,
        user,
        setuser,
        log,
        getUser,
        depature,
        setDepature,
        form,
        userForm,
        setUserForm,
        setChange,
        reviews,
        review,
        note,
        getreview,
        setNote,
      }}
    >
      {children}
    </Contextet.Provider>
  );
}
