import React from "react";
import { BiBusSchool } from "react-icons/bi";
import { AiOutlineArrowRight } from "react-icons/ai";
import Card from "react-bootstrap/Card";
import { useContext } from "react";
import { Contextet } from "../Context/Context";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useState } from "react";
import Stack from "@mui/material/Stack";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { toast } from "react-hot-toast";
import "./Search.css";
import { MdArrowBack } from "react-icons/md";
export default function Search() {
  let { drop, pick, setDate, date, setPick, dropMe, setDrop, me, setMe } =
    useContext(Contextet);

  let { t } = useTranslation();
  const [value, setValue] = useState(null);
  const [minDate, setMinDate] = useState(null);

  function select() {
    let x = document.getElementById("checky");
    let y = document.getElementById("check");

    if (x.value == `${localStorage.getItem("hotel")}`) {
      setDrop("");
      y.innerHTML = `
      <option value="airport french side">${t("choose_destation")}</option>
      <option value="airport french side">${t(
        "Aéroport_côté_Français"
      )}</option>
      <option>private flight</option>
      <option value="airport suisse side">${t("airport suisse side")}</option>
      `;
    } else {
      y.innerHTML = `
      <option id='hotel' value=${localStorage.getItem("hotel")}>${t(
        "Hotel"
      )}</option>
      `;
      setDrop(`${localStorage.getItem("hotel")}`);
    }
  }

  function inpur(e) {
    if (localStorage.getItem("token") != null) {
      var date = new Date();
      var tdate = date.getDate();
      var month = date.getMonth() + 1;
      var year = date.getUTCFullYear();
      if (month < 10) {
        month = "0" + month;
      }
      if (tdate < 10) {
        tdate = "0" + tdate;
      }
      var minDate = year + "-" + month + "-" + tdate;

      setMinDate(minDate);
    }
  }

  useEffect(() => {
    inpur();
    return () => {
      setMe("");
      setDate(null);
      setDrop("");
      setPick("");
    };
  }, []);

  return (
    <div className="text-center">
      <Fade top>
        {localStorage.getItem("token") == null ? (
          <div className="responsive-date d-flex justify-content-between align-items-center mt-5  w-100">
            <h3>{t("log_first")}</h3>
            <div>
              <Link to="/login" className="btn btn-warning">
                {t("login")}
              </Link>
              <Link to="/register" className="btn btn-warning mx-3">
                {t("Register")}
              </Link>
            </div>
          </div>
        ) : (
          <div className="mt-5 layOut">
            <div className="head">
              <Link to='/'>
                <MdArrowBack size={25} className="icon_back" />
              </Link>
              <p>Search Available Trips</p>
            </div>

            {/* Start Body */}
            <div className="parent_inputs">
              
              {/* Start Choose your pick up */}
              <div className="box_input">
                  <div className="icon_left">
                    <img src="Group (4).png" alt="" />
                  </div>
                  <select
                    className="p-3 inputbus"
                    id="checky"
                    onClick={(e) => {
                      setPick(e.target.value);
                    }}
                    onChange={(e) => {
                      setPick(e.target.value);
                      select();
                    }}
                  >
                    <option value="Choose your pick up">
                      {t("Choose_pick_up")}
                    </option>
                    <option id="hotel" value={localStorage.getItem("hotel")}>
                      {t("Hotel")}
                    </option>
                    <option value="airport french side">
                      {t("Aéroport_côté_Français")}
                    </option>
                    <option>private flight</option>
                    <option value="airport suisse side">
                      {t("Airport_Swiss_side")}
                    </option>
                  </select>
              </div>
              {/* End Choose your pick up */}

              {/* Start Destnation  */}
              <div className="box_input mt-4">
                  <div className="icon_left">
                    <img src="Group (5).png" alt="" />
                  </div>
                  <select
                      className="p-3 inputbus"
                      id="check"
                      onChange={(e) => {
                        setDrop(e.target.value);
                      }}
                    >
                      <option value="Choose your destination">
                        {t("choose_destation")}
                      </option>
                  </select>
              </div>
              {/* End Destnation  */}

              {/* Start Date  */}
              <div className="box_input mt-4">
                <div className="icon_left">
                    <img src="Group (6).png" alt="" />
                </div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack className="pl_5 inputbus">
                    <MobileDatePicker
                      className=""
                      label="pick your date"
                      value={value}
                      minDate={minDate}
                      inputFormat="YYYY-MM-DD"
                      onChange={(e) => {
                        let x = e.$y;
                        let y = e.$M + 1;
                        let z = e.$D;
                        setValue(e);
                        setDate(x + "-" + y + "-" + z);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Stack>
                </LocalizationProvider>
              </div>
              {/* End Date  */}

              {/* Start Ski Material */}
              {/* <div className="box_input mt-2">
                <FormGroup>
                  <FormControlLabel
                    className="pl_3"
                    control={
                      <Checkbox
                        onChange={(e) =>
                          localStorage.setItem("ski", e.target.checked)
                        }
                      />
                    }
                    label="Ski Matrial"
                  />
                </FormGroup>
              </div> */}
              {/* End Ski Meterial */}
              


              {/* Button Submite */}
              <button
                className="btn btn-primary mt-3 w-100"
                onClick={() => {
                  dropMe();
                }}
              >
                {t("Next")}
              </button>

            </div>

          </div>
        )}
        <div className="h3 text-danger mt-5">{me}</div>
      </Fade>
    </div>
  );
}
