import React, { useEffect, useRef, useState } from "react";
import Fade from "react-reveal/Fade";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import "./prices.css";

import { BiLeftArrowAlt } from "react-icons/bi";
import { Autocomplete } from "@react-google-maps/api";
import { loadGoogleMapsScript } from "./LoadScripting";
import axios from "axios";
import { toast } from "react-hot-toast";

export default function Prices() {
  const [loadingReq, setLoadingReq] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false);
  const [valueDate, setValueDate] = useState('');
  const [valueTime, setValueTime] = useState('');
  const [comment, setComment] = useState("");
  const [CountThing, setCountThing] = useState({
    adults: 0,
    infant: 0,
    child: 0,
    cats: 0,
    dogs: 0,
    small: 0,
    meduim: 0,
    big: 0,
  });

  let { t } = useTranslation();
  const navigate = useNavigate();

  const handleMinus = (type, what) => {
    if (what === "minus") {
      if (CountThing[type] === 0) return;
      setCountThing({ ...CountThing, [type]: CountThing[type] - 1 });
    } else {
      if (CountThing[type] === 10) return;
      setCountThing({ ...CountThing, [type]: CountThing[type] + 1 });
    }
  };

  const from = useRef();
  const to = useRef();

  useEffect(() => {
    loadGoogleMapsScript("AIzaSyBqB6CgEEbTrE5b2LV_xC4DLOtag9wBPaQ", ["places"])
      .then(() => {
        setIsLoaded(true);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleSendRideRequest = (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    const obj = {
      user: localStorage.getItem("user_id"),
      pickup_location:from.current.value,
      drop_location:to.current.value,
      date: valueDate,
      time: valueTime,
      adults: CountThing.adults,
      infant: CountThing.infant,
      children: CountThing.child,
      small_luggage: CountThing.small,
      meduim_luggage: CountThing.meduim,
      large_luggage: CountThing.big,
      message: comment,
      dogs: CountThing.dogs,
      cats: CountThing.cats,
    }

    const headers = {
      Authorization: `Bearer ${token}`
    };
    setLoadingReq(true)
    axios.post('https://kiro-travel.herokuapp.com/api/v1/shuttle/ride_request/', obj, { headers })
      .then((res) => {
        console.log(res.status);
        if(res.status === 201) {
          toast.success('Done')
          setValueDate('')
          setValueTime('')
          setComment('')
          setCountThing({
            adults: 0,
            infant: 0,
            child: 0,
            cats: 0,
            dogs: 0,
            small: 0,
            meduim: 0,
            big: 0,
          })
          from.current.value = ''
          to.current.value = ''
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoadingReq(false))
    
  }

  if (!isLoaded) {
    return (
      <div className="flex justify-center items-center h-[100vh] bg-white">
          loading...
      </div>
    ) 
}

  return (
    <>
      <Fade top>
        {localStorage.getItem("token") == null ? (
          <div className="responsive-date d-flex justify-content-between align-items-center mt-5  w-100">
            <h3>{t("log_first")}</h3>
            <div>
              <Link to="/login" className="btn btn-warning">
                {t("login")}
              </Link>
              <Link to="/register" className="btn btn-warning mx-3">
                {t("Register")}
              </Link>
            </div>
          </div>
        ) : (
          <div className="mt-5 mb-5">
            <div className="parent_ride">
              <p>Ride Request</p>
              <span onClick={() => navigate("/")}>
                <BiLeftArrowAlt size={25} />
              </span>

              {/*  From & To  */}
                  
              <div className="ride_form">
                  {/* input */}
                  <div className="box_input">
                    <span>Pick up From</span>
                    <Autocomplete className="auto">
                      <input required ref={from} type="text" />
                    </Autocomplete>
                  </div>

                <div className="icon_tt">
                  <img src="ride.png" alt="" />
                </div>

                {/* input */}
                <div className="box_input">
                  <span>Destination</span>
                  <Autocomplete className="auto">
                    <input required ref={to} type="text" />
                  </Autocomplete>
                </div>
              </div>
  
              {/*  Date & Time  */}
              <div className="ride_form">
                {/* input */}
                <div className="box_input">
                  <span>Pick up Date</span>
                  <input required type="date" value={valueDate} onChange={(e) => setValueDate(e.target.value)} />
                </div>

                {/* input */}
                <div className="box_input">
                  <span>Pick up Time</span>
                  <input required type="time" value={valueTime} onChange={(e) => setValueTime(e.target.value)} />
                </div>
              </div>

              {/* Things */}
              <div className="ride_numbers">
                <div className="ride_box">
                  <div>
                    <div className="d-flex justify-content-between align-items-center pb-3">
                      <div className="d-flex align-items-center">
                        <p className="mr-3">
                          <img src="ride/person.png" alt="" />
                        </p>
                        <p className="font-weight-bold">Adults</p>
                      </div>
                      <div className="d-flex justify-content-between align-items-center custem_counter">
                        <span
                          onClick={() => handleMinus("adults", "minus")}
                          className="minus"
                        >
                          -
                        </span>
                        <span className="count">{CountThing.adults}</span>
                        <span
                          onClick={() => handleMinus("adults", "plus")}
                          className="plus"
                        >
                          +
                        </span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center pb-3">
                      <div className="d-flex align-items-center">
                        <p className="mr-3">
                          <img src="ride/two.png" alt="" />
                        </p>
                        <p className="font-weight-bold">Infant</p>
                      </div>
                      <div className="d-flex justify-content-between align-items-center custem_counter">
                        <span
                          onClick={() => handleMinus("infant", "minus")}
                          className="minus"
                        >
                          -
                        </span>
                        <span className="count">{CountThing.infant}</span>
                        <span
                          onClick={() => handleMinus("infant", "plus")}
                          className="plus"
                        >
                          +
                        </span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center pb-3">
                      <div className="d-flex align-items-center">
                        <p className="mr-3">
                          <img src="ride/three.png" alt="" />
                        </p>
                        <p className="font-weight-bold">Child</p>
                      </div>
                      <div className="d-flex justify-content-between align-items-center custem_counter">
                        <span
                          onClick={() => handleMinus("child", "minus")}
                          className="minus"
                        >
                          -
                        </span>
                        <span className="count">{CountThing.child}</span>
                        <span
                          onClick={() => handleMinus("child", "plus")}
                          className="plus"
                        >
                          +
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="mt-3">
                    <div className="d-flex justify-content-between align-items-center pb-3">
                      <div className="d-flex align-items-center">
                        <p className="mr-3">
                          <img src="ride/foure.png" alt="" />
                        </p>
                        <p className="font-weight-bold">Cats</p>
                      </div>
                      <div className="d-flex justify-content-between align-items-center custem_counter">
                        <span
                          onClick={() => handleMinus("cats", "minus")}
                          className="minus"
                        >
                          -
                        </span>
                        <span className="count">{CountThing.cats}</span>
                        <span
                          onClick={() => handleMinus("cats", "plus")}
                          className="plus"
                        >
                          +
                        </span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center pb-3">
                      <div className="d-flex align-items-center">
                        <p className="mr-3">
                          <img src="ride/dog.png" alt="" />
                        </p>
                        <p className="font-weight-bold">Dogs</p>
                      </div>
                      <div className="d-flex justify-content-between align-items-center custem_counter">
                        <span
                          onClick={() => handleMinus("dogs", "minus")}
                          className="minus"
                        >
                          -
                        </span>
                        <span className="count">{CountThing.dogs}</span>
                        <span
                          onClick={() => handleMinus("dogs", "plus")}
                          className="plus"
                        >
                          +
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="ride_box">
                  <div className="animals">
                    <div className="d-flex justify-content-between align-items-center pb-3">
                      <div className="d-flex align-items-center">
                        <p className="mr-3">
                          <img src="ride/small.png" alt="" />
                        </p>
                        <p className="font-weight-bold">Small</p>
                      </div>
                      <div className="d-flex justify-content-between align-items-center custem_counter">
                        <span
                          onClick={() => handleMinus("small", "minus")}
                          className="minus"
                        >
                          -
                        </span>
                        <span className="count">{CountThing.small}</span>
                        <span
                          onClick={() => handleMinus("small", "plus")}
                          className="plus"
                        >
                          +
                        </span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center pb-3">
                      <div className="d-flex align-items-center">
                        <p className="mr-3">
                          <img src="ride/med.png" alt="" />
                        </p>
                        <p className="font-weight-bold">Meduim</p>
                      </div>
                      <div className="d-flex justify-content-between align-items-center custem_counter">
                        <span
                          onClick={() => handleMinus("meduim", "minus")}
                          className="minus"
                        >
                          -
                        </span>
                        <span className="count">{CountThing.meduim}</span>
                        <span
                          onClick={() => handleMinus("meduim", "plus")}
                          className="plus"
                        >
                          +
                        </span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center pb-3">
                      <div className="d-flex align-items-center">
                        <p className="mr-3">
                          <img src="ride/med.png" alt="" />
                        </p>
                        <p className="font-weight-bold">Big</p>
                      </div>
                      <div className="d-flex justify-content-between align-items-center custem_counter">
                        <span
                          onClick={() => handleMinus("big", "minus")}
                          className="minus"
                        >
                          -
                        </span>
                        <span className="count">{CountThing.big}</span>
                        <span
                          onClick={() => handleMinus("big", "plus")}
                          className="plus"
                        >
                          +
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Comments  */}
              <div className="comment">
                <h5>Comments</h5>
                <textarea
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  name=""
                  id=""
                  cols="30"
                  rows="5"
                  placeholder="Write a comment..."
                ></textarea>
              </div>

              {/* Send  */}
              <button disabled={loadingReq} onClick={(e) => handleSendRideRequest(e)} className="send_btn">Send</button>
            </div>
          </div>
        )}
      </Fade>
    </>
  );
}
