import React, { useContext, useEffect, useState } from 'react'
import  '../Styles/taxi.css'
import { Link, useNavigate } from 'react-router-dom'
import { TaxiUse } from '../Context/TaxiContext';
import Card from 'react-bootstrap/Card';
import {BiTaxi} from "react-icons/bi"
import {BsArrowLeftRight , BsFillHandbagFill} from "react-icons/bs"
import {MdOutlineChildFriendly} from "react-icons/md"
import {AiFillMinusCircle , AiFillPlusCircle} from "react-icons/ai"
import {FcBusinessman} from "react-icons/fc"
import {GiRomanToga , GiLightBackpack} from "react-icons/gi"
import {MdLuggage} from "react-icons/md"
import { useTranslation} from "react-i18next";
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Stack from '@mui/material/Stack';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { toast } from 'react-hot-toast';
import { CircularProgress } from '@mui/material';


export default function Taxi() {


 let {setTiming ,timing,  date , setDate,  taxiData , visa , sendInfo , setTaxi  , s , check , getTaxi  , setCheck , setLast , setFirst ,loading} =  useContext(TaxiUse)
 
 let [pay , setPay] = useState("")
 let {t} = useTranslation()

 const [value, setValue] = useState(null);
 const [minDate, setMinDate] = useState(null);
 const [time, setTime] = useState(null);
 const [childs, setChilds] = useState(0);
 const [teen, setTeen] = useState(0);
 const [old, setOld] = useState(0);
 const [handBag, setHandBag] = useState(0);
 const [packBag, setPackBag] = useState(0);
 const [luggage, setLuggage] = useState(0);
 const [taxiNumber, setTaxiNUmber] = useState(1);
 const [flighNumber, setFlightNUmber] = useState(null);
 const [roomNumber, setRoomNumber] = useState(null);


 function inpur(e) {
  if(localStorage.getItem("token") != null){
    var date = new Date()
    var tdate = date.getDate()
    var month = date.getMonth() + 1
    var year = date.getUTCFullYear()
    if(month < 10){
      month = '0' + month
    }
    if(tdate < 10){
      tdate = '0' + tdate
    }
    var minDate = year + "-" + month + "-" + tdate

    setMinDate(minDate)
  }
 }

 let navigate = useNavigate()

 let x = JSON.parse(localStorage.getItem('Taxi-result'))


 function ski(){
  
    if(localStorage.getItem('ski') != null ||  localStorage.getItem('ski') == false){
      toast.error(t('no_ski'))
    }
    
 }


 useEffect(() => {
  inpur()
  ski()
   return () => {
    localStorage.removeItem('taxi-type')
    localStorage.removeItem('Taxi-result')
    localStorage.removeItem('ski')
    setFirst(null)
    setLast(null)
   }
 }, [])
 

  return (

    <div>
         <div className='h3 text-danger mt-5 text-center'>{check}</div>
      <div className='d-flex justify-content-around flex-wrap'>
     
  
      
        {
           localStorage.getItem("token") == null ? (
            (
              <div className='mt-2 d-flex mt-5 justify-content-between flex-wrap responsive-date w-100'>
              <h3>{t('log_first')}</h3>
            <div>
            <Link to='/login' className='btn btn-warning'>{t('login')}</Link>
            <Link to='/register' className='btn btn-warning mx-3'>{t('Register')}</Link>
            </div>
              </div>
            ) ): ((
                 x == '' || x == null || x.length == 2 ? (
                <div className='text-center mt-5 h4'>{t('no_taxi')}</div>
                ) : ( 
                      x.map((e)=>
                      <Card key={e.id}    style={{ width: '30rem' , textAlign : "center" , borderRadius: "55px" }} className="mt-3 responsive-card main-card"> 
                      <Card.Body>
                        <div className='d-flex justify-content-between align-items-center responsive-route'>
                        <Card.Title  className="d-flex align-items-center justify-content-center card-titles"> {e.pickup_location}</Card.Title>
                        <div className='car-icon-title'>
                        <Card.Title className='m-0'><BiTaxi /></Card.Title>
                        <Card.Title><BsArrowLeftRight /></Card.Title>
                        </div>
                        <Card.Title className="d-flex align-items-center card-titles">{e.drop_location}</Card.Title>
                        </div>
                        <div style={{marginTop:"0.5em"}} className={localStorage.getItem('ski') == null || localStorage.getItem('ski') == false ? 'd-flex justify-content-between responsive-date' : 'd-flex justify-content-center responsive-date mb-3'}>
                        <p className={localStorage.getItem('ski') == null || localStorage.getItem('ski') == false ? 'd-block van-cedan-para' : 'd-none'}>{t('pricing')} {t('sedan')} : {e.price_sedan}€ </p>
                        <p className='van-cedan-para'>{t('pricing')} van : {e.price_van} €</p> 
                        </div>
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                        <select className='vehicle-select-menu' id={e.id} onChange={(e)=>{getTaxi(e)
                        }}>
                       <option value="">{t('taxi_type')}</option>
                     
                       <option value="sedan" className={localStorage.getItem('ski') == null || localStorage.getItem('ski') == false ? 'd-block' : 'd-none'}>{t('sedan')}</option>
                       <option value="van">van</option>
                        </select>
                        <div className='d-flex mt-3'>
                        <input type='text' className='mx-3 w-75 name-room' placeholder={t('enter_name')} onChange={(e)=>{setFirst(e.target.value)}}/>
                        {
                            e.drop_location == localStorage.getItem('hotel') ? 
                              <input type='text' className='mx-3 w-75 name-room' placeholder={t('FlightNumber')} onChange={(e)=>setFlightNUmber(e.target.value)} />
                            :  <input type='text' className=' w-75 name-room' placeholder={t('RoomNumber')} onChange={(e)=>setRoomNumber(e.target.value)} />
                        }
                        </div>  
                        <span className='text-left w-90 mt-5 bold text-danger'>{t('NumberofPassengers')}</span>
                        <div className='d-flex w-100 justify-content-around'>
                        <div className='d-flex mt-3'>
                        <div>
                          <p>{t('child')}</p>
                        <span>
                          <MdOutlineChildFriendly />
                        </span>
                        <span className='profile' onClick={()=>{
                          if(childs === 0){
                            toast.error('at least 1 person has to attend')
                          }else{
                            setChilds((childs)=>childs-1)
                          }                
                        }}>
                          <AiFillMinusCircle />
                        </span>
                        <span>{childs}</span>
                        <span className='profile' onClick={()=>{
                          
                          if(teen + old + childs === 4 && localStorage.getItem('taxi-type') == 'sedan'){
                            toast.error('you can select maximum 4 person or you can select van')
                          }else if(teen + old + childs === 6 && localStorage.getItem('taxi-type') == 'van'){
                            toast.error('you can select maximum 6 person')
                          }else{
                            setChilds((childs)=>childs+1)
                          }
                          }}>
                          <AiFillPlusCircle />
                        </span>
                        </div>
                        </div>
                        <div className='d-flex mt-3'>
                          <div>
                          <p>{t('teen')}</p>
                        <span>
                          <FcBusinessman />
                        </span>
                        <span className='profile' onClick={()=>{
                          if(teen === 0){
                            toast.error('at least 1 person has to attend')
                          }else{
                            setTeen((teen)=>teen-1)
                          }                
                        }}>
                          <AiFillMinusCircle />
                        </span>
                        <span>{teen}</span>
                        <span className='profile' onClick={()=>{
                          if(teen + old + childs === 4 && localStorage.getItem('taxi-type') == 'sedan'){
                            toast.error('you can select maximum 4 person or you can select van')
                          }else if(teen + old + childs === 6 && localStorage.getItem('taxi-type') == 'van'){
                            toast.error('you can select maximum 6 person')
                          }else{
                            setTeen((teen)=>teen+1)
                          }
                          }}>
                          <AiFillPlusCircle />
                        </span>
                        </div>
                        </div>
                        <div className='d-flex mt-3'>
                          <div>
                          <p>{t('oldAge')}</p>
                        <span>
                          <GiRomanToga />
                        </span>
                        <span className='profile' onClick={()=>{
                          if(old === 0){
                            toast.error('at least 1 person has to attend')
                          }else{
                            setOld((old)=>old-1)
                          }                
                        }}>
                          <AiFillMinusCircle />
                        </span>
                        <span>{old}</span>
                        <span className='profile' onClick={()=>{
                          if(teen + old + childs === 4 && localStorage.getItem('taxi-type') == 'sedan'){
                            toast.error('you can select maximum 4 person or you can select van')
                          }else if(teen + old + childs === 6 && localStorage.getItem('taxi-type') == 'van'){
                            toast.error('you can select maximum 6 person')
                          }else{
                            setOld((old)=>old+1)
                          }
                          }}>
                          <AiFillPlusCircle />
                        </span>
                        </div>
                        </div>
                        </div>
                        <span className='text-left w-90 mt-5 mb-3 bold text-danger'>{t('Numberofluggages')}</span>
                        <div className='d-flex w-100 justify-content-around'>
                        <div className='d-flex mt-3'>
                          <div>
                          <p>{t('Small')}</p>
                        <span>
                          <BsFillHandbagFill />
                        </span>
                        <span className='profile' onClick={()=>{
                          if(handBag === 1){
                            toast.error('at least 1 bag has to attend')
                          }else{
                            setHandBag((bag)=>bag-1)
                          }                
                        }}>
                          <AiFillMinusCircle />
                        </span>
                        <span>{handBag}</span>
                        <span className='profile' onClick={()=>{setHandBag((bag)=>bag+1)}}>
                          <AiFillPlusCircle />
                        </span>
                        </div>
                        </div>
                        <div className='d-flex mt-3'>
                        <div>     
                        <p>{t('Meduim')}</p>
                        <span>
                          <GiLightBackpack />
                        </span>
                        <span className='profile' onClick={()=>{
                          if(packBag === 1){
                            toast.error('at least 1 bag has to attend')
                          }else{
                            setPackBag((bag)=>bag-1)
                          }                
                        }}>
                          <AiFillMinusCircle />
                        </span>
                        <span>{packBag}</span>
                        <span className='profile' onClick={()=> setPackBag((bag)=>bag+1)}>
                          <AiFillPlusCircle />
                        </span>
                        </div>
                        </div>
                        <div className='d-flex mt-3'>
                        <div>     
                        <p>{t('Big')}</p>
                        <span>
                          <MdLuggage />
                        </span>
                        <span className='profile' onClick={()=>{
                          if(luggage === 1){
                            toast.error('at least 1 bag has to attend')
                          }else{
                            setLuggage((bag)=>bag-1)
                          }                
                        }}>
                          <AiFillMinusCircle />
                        </span>
                        <span>{luggage}</span>
                        <span className='profile' onClick={()=> setLuggage((bag)=>bag+1)}>
                          <AiFillPlusCircle />
                        </span>
                        </div>
                        </div>
                        
                        </div>

                        <div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack className='mt-3  justify-content-center align-items-center'>
                               <MobileDatePicker
                                className='width'
                                 label={t('date')}
                                 value={value}
                                 minDate={minDate}
                                 inputFormat="YYYY-MM-DD"
                                 onChange={(e) => {
                                   let x = e.$y
                                   let y = e.$M + 1
                                   let z = e.$D
                                   setValue(e);
                                   setDate(x + "-" + y + "-" + z)
                                 }}
                                 renderInput={(params) =><TextField {...params}  />}
                               />

                        <MobileTimePicker
                                  className='width mt-2'
                                  label={t('time')}
                                  value={time}
                                  onChange={(newValue) => {
                                    setTime(newValue);
                                    let x = newValue.$H
                                    let y = newValue.$m
                                    if(x == 0){
                                      x = 12
                                    }
                                    setTiming( x + ":" + y)
                                  }}
                                  renderInput={(params) => <TextField {...params} />}
                                />
                                 </Stack>
                            </LocalizationProvider>
                        </div>
                        </div>

                        
                        
                        <div className="payment d-flex justify-content-between mt-5 responsive-date">
                        <div>
                          {
                              loading == true ?  <CircularProgress /> : 
                              <button className='btn btn-warning btn-responsive' onClick={()=> { 

                                if(teen + old + childs >= 4 && localStorage.getItem('taxi-type') == 'sedan'){
                                  console.log("sedan")
                                  toast.error('you can select maximum 4 person or you can select van')
                                }else if(teen + old + childs >= 6 && localStorage.getItem('taxi-type') == 'van'){
                                  console.log("van")
                                  toast.error('you can select maximum 6 person')
                                }else{
                                  sendInfo(e.id , taxiNumber , packBag , handBag , luggage , flighNumber , childs , teen , old , roomNumber)
                                }


                            } 
                              }>{t('pay_cash')}</button>
                          }
                        
                          </div>
                          <div>
                            {
                              loading == true ? <CircularProgress /> : 
                              <button className='btn btn-danger ' onClick={s =="sedan" ? ( ()=>{
                                if(teen + old + childs >= 4 && localStorage.getItem('taxi-type') == 'sedan'){
                                  toast.error('you can select maximum 4 person or you can select van')
                                }else if(teen + old + childs >= 6 && localStorage.getItem('taxi-type') == 'van'){
                                  toast.error('you can select maximum 6 person')
                                }else{
                                  visa( e.id , taxiNumber , packBag , handBag , luggage , flighNumber , childs , teen , old , roomNumber)

                                  }
                               
                            }
                              
                            ) : ( ()=>{
                              if(teen + old + childs >= 4 && localStorage.getItem('taxi-type') == 'sedan'){
                                toast.error('you can select maximum 4 person or you can select van')
                              }else if(teen + old + childs >= 6 && localStorage.getItem('taxi-type') == 'van'){
                                toast.error('you can select maximum 6 person')
                              }else{
                                visa( e.id , taxiNumber , packBag , handBag , luggage , flighNumber , childs , teen , old , roomNumber)

                                }                              
                            }
                              
                            )}>{t('pay_visa')}</button>
                            }
                         
                  
                          </div>
 
                        </div>
          
                      </Card.Body>
                    </Card>
                    
                    )
                    
                  )
)
            )
        }
 
    </div>




    <div className='text-center'>
    <Link to="/TaxiSearch" className='btn btn-primary my-4' onClick={()=> {localStorage.removeItem('taxi-type')
  }}>{t('Back')}</Link>

    </div>
    </div>
  )
}
