import axios from 'axios'
import React from 'react'
import { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import { Contextet } from '../Context/Context';
import Comment from './Comment';
import { useTranslation} from "react-i18next";


export default function Reviews() {


  let {prvPgae ,reviews , reviewsNe , page}=  useContext(Contextet)

  let {t} = useTranslation()


  function myFunction() {
    window.scrollTo(0, 0);
  }
    useEffect(()=>{
      reviews()
      myFunction()
    },[])

  return (
    <Fade bottom>
    <div>
     

     {
          localStorage.getItem("token") == null ? (
            (
              <div className='responsive-date d-flex mt-5 justify-content-between align-items-center  w-100'>
                <h3>{t('log_first')}</h3>
            <div>
            <Link to='/login' className='btn btn-warning'>{t('login')}</Link>
            <Link to='/register' className='btn btn-warning mx-3'>{t('Register')}</Link>
                </div>
              </div>
           
            )
         
        ) : (<Comment />)
     }


     {
      localStorage.getItem('token') ? (

        <>
     <div className='mt-5 text-center'>
     <button className= { prvPgae ? ('btn btn-danger') : ('btn btn-danger disabled')} onClick={()=>{reviewsNe(prvPgae)}} >{t('pervious')}</button>

     <button className={
      page ? ('btn btn-success mx-3') : ('btn btn-danger mx-3 disabled')
     }  onClick={()=>{reviewsNe(page)}}>{t('Next')}</button>

     </div>
 
        </>
        
      ) : ('')
     }

    <div className='text-center mb-3'>
    <Link to="/" className='btn btn-primary mt-4 text-white'>{t('Back')}</Link>
    </div>
      
    </div>
    </Fade>
  )
}
