import React from 'react'
import { useContext } from 'react';
import {AiOutlineCheck} from "react-icons/ai"
import { Link } from 'react-router-dom';
import { Contextet } from '../Context/Context';
import { IconContext } from "react-icons";
import { useTranslation} from "react-i18next";
import { useEffect } from 'react';
import html2canvas from "html2canvas"
import jsPDF from 'jspdf';
import { TaxiUse } from '../Context/TaxiContext';


export default function Thanks() {
  let {customerID , setCustomerID , setUserForm}=  useContext(Contextet)
 let {warning , setWarning} =  useContext(TaxiUse)

  let {t} = useTranslation()

 
  function pdf() {
    let input = document.getElementById('pdf')
    html2canvas(input , {logging : true , letterRendering : 1 , useCORS : true}).then(canvas =>{
      const imgWidth = 208
      const imgHeight = canvas.height * imgWidth / canvas.width
      const imgData = canvas.toDataURL('img/png')
      const pdf = new jsPDF('p' , 'mm' , 'a4')
      pdf.addImage(imgData , 'PNG' , 0 , 0 ,imgWidth ,imgHeight  )
      pdf.save('reservation.pdf')
    })
  }

  function myFunction() {
    var copyText = document.getElementById("reservation");
    navigator.clipboard.writeText(copyText.innerText);
    var text  = document.getElementById('copy')
    text.innerText = 'copied'
    setTimeout(() => {
      text.innerText = ''
    }, 2000);
  }


  function id(){
    if(localStorage.getItem('reservatio_id')){
      setCustomerID(localStorage.getItem('reservatio_id'))
    }
  }

  useEffect(() => {
    id()
    return () => {
      localStorage.removeItem('user')
      localStorage.removeItem('price')
      localStorage.removeItem('reservatio_id')
      localStorage.removeItem('status')
      localStorage.removeItem('busId')
      localStorage.removeItem('shuttle')
      setUserForm({first_name : '' , last_name: '' ,phone: '' ,no_of_bags : '' , id : localStorage.getItem("busId") , seats_booked : '' , user : localStorage.getItem("user_id") , room : '' })
    }
  }, [customerID])
 

  return (
    <div className='mt-5 mb-5' id="pdf">
        <div className='text-center Thank-card p-3 m-auto'>
            <h2>{t('thanks')}</h2>
          {
            customerID ? (
              <>
              <h2 className='h4 text-danger'>{t('reservation_id')}:<span id='reservation'> {customerID}</span></h2>
              <p id='copy' className='text-warning bold'></p>
              </>
            ) : ('')
          }
            <div className=' mt-5'>
              <IconContext.Provider value={{ color : "white", size : "2em" }}>
                <AiOutlineCheck className='todo'/>
                </IconContext.Provider>
                </div>
                <div className='mt-5 warning'>
                  {
                    warning != null ? (warning) : ('')
                  }
                </div>
            <p className='mt-3'>{t('email')}</p>
            <div className='btns-thanks'>
            <Link to='/' className='btn btn-success' onClick={()=> {
              setCustomerID('')
              setWarning(null)
          }} >{t('home')}</Link>
            {
            customerID ? (
              <>
              <button onClick={pdf} className='btn btn-primary  '>{t('Download')}</button>
              <button onClick={myFunction} className='btn btn-warning '>{t('copy')}</button>
              </>
            ) : ('')
          }
          </div>
        </div>
    </div>
  )
}
