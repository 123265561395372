import React from "react";
import { Card } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { ImCancelCircle } from "react-icons/im";
import { IconContext } from "react-icons";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { useEffect } from "react";

export default function UnsuccPayment() {
  let { t } = useTranslation();
  let navigate = useNavigate();
  function status() {
    let x = JSON.parse(localStorage.getItem("booking"));
    if (localStorage.getItem("status") != null) {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("token")}`
      );
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `https://kiro-travel.herokuapp.com/api/v1/shuttle/payment-status/?session_id=${localStorage.getItem(
          "status"
        )}&trip_id=${localStorage.getItem("busId")}&first_name=${
          x.first_name
        }&last_name=${x.last_name}&room_number=${x.room}&seats_booked=${
          x.seats_booked
        }&no_of_bags=${x.no_of_bags}&user_id=${localStorage.getItem(
          "user_id"
        )}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          // console.log(result);
          let y = result;
          let z = y.data[0];
          localStorage.setItem("reservatio_id", z.reservation_id);
        })
        .catch((error) => {
          console.log(error);
        });
      // .finally(() => setLoader(true));
    } else {
      toast.error(t("NoPayment"));
      navigate("/");
    }
  }

  useEffect(()=>{
    status()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  return (
    <div className="mt-5">
      <Card style={{ width: "fit-content" }} className="p-3 m-auto">
        <Card.Body className="text-center">
          <Card.Title className="text-center text-danger">
            Your payment has been cancelled
          </Card.Title>
          <div className="my-3">
            <IconContext.Provider value={{ size: "5em", color: "red" }}>
              <ImCancelCircle />
            </IconContext.Provider>
          </div>
          <h2 className="bold h5 ">Your payment was cancelled</h2>
          <p className="m-0 p-0">
            We cancelled your payment as per your request
          </p>
        </Card.Body>
        <div className="text-center">
          <Link to="/" className="btn btn-primary text-white">
            Back Home
          </Link>
        </div>
      </Card>
    </div>
  );
}
