import React from 'react'
import {BiTaxi} from 'react-icons/bi'
import {AiOutlineArrowRight} from "react-icons/ai"
import {IoMdSwap} from "react-icons/io"
import Card from 'react-bootstrap/Card';
import { useContext } from 'react';
import { Contextet } from '../Context/Context';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import { useTranslation} from "react-i18next";
import { TaxiUse } from '../Context/TaxiContext';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { CircularProgress } from '@mui/material';


export default function Taxi_search() {

    let { taxiData , drop ,  pick , setPick , dropMe  , busData   , setDrop, me ,setMe , loading}=  useContext(TaxiUse)

    let {t} = useTranslation()

    useEffect(() => {
    
      return () => {
        setMe('')
        setDrop('')
        setPick('')
      }
    }, [])
    


    function select() {
      let x = document.getElementById('checky')
      let y = document.getElementById('check')
  
      if(x.value == `${localStorage.getItem('hotel')}`){
        setDrop('')
        y.innerHTML = `
      <option value="airport french side">${t('choose_destation')}</option>
        <option value="airport french side">${t('Aéroport_côté_Français')}</option>
        <option value="airport suisse side">${t('Airport_Swiss_side')}</option>
        <option value="Cornavin Train Station">${t('Cornavin_Train')}</option>
        <option value="Palexpo Arena">Palexpo Arena</option>
        <option value="United Nations ( UN )">${t('United_Nations')}</option>
        <option value="WHO ( World Health Organisation )">${t('WHO')}</option>
        <option value="WTO ( World Trade Organisation )">${t('wto')}</option>
        <option value="ITU ( International Telecommunication Union )">${t('itu')}</option>
        <option value="Quai Wilson Geneva">${t('quai')}</option>
        <option value="Quai de Mont Blanc Geneva">${t('quai_Mont')}</option>
        <option value="Geneva Old City">${t('old')}</option>
        <option value="Hospital de la Tour">${t('Hospital')}</option>
        <option value="Hospital HUG ( Hôpital Universitaire de Genève )">${t("HUG")}</option>
        <option value="Annecy">Annecy</option>
        <option value="Annemasse">Annemasse</option>
        <option value="CERN Prevessin">${t('cern')}</option>
        <option value="CERN Meyrin">CERN Meyrin</option>
        <option value="Val Thoiry">Val Thoiry</option>
        <option value="Bellegarde sur Valserine Train Station">${t('Bellegarde')}</option>
        <option value="Tag Aviation">Tag Aviation</option>
        <option value="Jet Aviation">Jet Aviation</option>
        <option value="Signature Aviation">Signature Aviation</option>
        <option value="Dassault Aviation">Dassault Aviation</option>
        `

      }else{
        y.innerHTML = `
        <option id='hotel' value=${localStorage.getItem('hotel')}>${t('Hotel')}</option>
        `
        setDrop(`${localStorage.getItem('hotel')}`)
      }
    } 

    function revert(){
      let x = document.getElementById('checky')
      let y = document.getElementById('check')
      if(pick == `${localStorage.getItem('hotel')}`){
        x.innerHTML = `
        <option value="airport french side">${drop}</option>
        <option value="airport french side">Aéroport côté Français</option>
        <option value="airport suisse side">airport suisse side</option>
        <option value="Cornavin Train Station">Cornavin Train Station</option>
        <option value="Palexpo Arena">Palexpo Arena</option>
        <option value="United Nations ( UN )">United Nations ( UN )</option>
        <option value="WHO ( World Health Organisation )">WHO ( World Health Organisation )</option>
        <option value="WTO ( World Trade Organisation )">WTO ( World Trade Organisation )</option>
        <option value="ITU ( International Telecommunication Union )">ITU ( International Telecommunication Union )</option>
        <option value="Quai Wilson Geneva">Quai Wilson Geneva</option>
        <option value="Quai de Mont Blanc Geneva">Quai de Mont Blanc Geneva</option>
        <option value="Geneva Old City">Geneva Old City</option>
        <option value="Hospital de la Tour">Hospital de la Tour</option>
        <option value="Hospital HUG ( Hôpital Universitaire de Genève )">Hospital HUG ( Hôpital Universitaire de Genève )</option>
        <option value="Annecy">Annecy</option>
        <option value="Annemasse">Annemasse</option>
        <option value="CERN Prevessin">CERN Prevessin</option>
        <option value="CERN Meyrin">CERN Meyrin</option>
        <option value="Val Thoiry">Val Thoiry</option>
        <option value="Bellegarde sur Valserine Train Station">Bellegarde sur Valserine Train Station</option>
        <option value="Tag Aviation">Tag Aviation</option>
        <option value="Jet Aviation">Jet Aviation</option>
        <option value="Signature Aviation">Signature Aviation</option>
        <option value="Dassault Aviation">Dassault Aviation</option>
        `
        y.innerHTML = `
        <option id='hotel' value=${localStorage.getItem('hotel')}>My Hotel</option>
        `
      }else{
        y.innerHTML = `
        <option value="airport french side">${pick}</option>
        <option value="airport french side">Aéroport côté Français</option>
        <option value="airport suisse side">airport suisse side</option>
        <option value="Cornavin Train Station">Cornavin Train Station</option>
        <option value="Palexpo Arena">Palexpo Arena</option>
        <option value="United Nations ( UN )">United Nations ( UN )</option>
        <option value="WHO ( World Health Organisation )">WHO ( World Health Organisation )</option>
        <option value="WTO ( World Trade Organisation )">WTO ( World Trade Organisation )</option>
        <option value="ITU ( International Telecommunication Union )">ITU ( International Telecommunication Union )</option>
        <option value="Quai Wilson Geneva">Quai Wilson Geneva</option>
        <option value="Quai de Mont Blanc Geneva">Quai de Mont Blanc Geneva</option>
        <option value="Geneva Old City">Geneva Old City</option>
        <option value="Hospital de la Tour">Hospital de la Tour</option>
        <option value="Hospital HUG ( Hôpital Universitaire de Genève )">Hospital HUG ( Hôpital Universitaire de Genève )</option>
        <option value="Annecy">Annecy</option>
        <option value="Annemasse">Annemasse</option>
        <option value="CERN Prevessin">CERN Prevessin</option>
        <option value="CERN Meyrin">CERN Meyrin</option>
        <option value="Val Thoiry">Val Thoiry</option>
        <option value="Bellegarde sur Valserine Train Station">Bellegarde sur Valserine Train Station</option>
        <option value="Tag Aviation">Tag Aviation</option>
        <option value="Jet Aviation">Jet Aviation</option>
        <option value="Signature Aviation">Signature Aviation</option>
        <option value="Dassault Aviation">Dassault Aviation</option>
        `
        x.innerHTML = `
        <option id='hotel' value=${localStorage.getItem('hotel')}>My Hotel</option>
        `
        setDrop(`${localStorage.getItem('hotel')}`)
      }
      setPick(drop)
      setDrop(pick)
      var image = document.getElementById("myImage");
      if(image.classList.contains("rotate")){
      image.classList.remove("rotate");
      }else{
        image.classList.add("rotate");
      }
    }







  return (
    <div className='text-center'>
    <Fade top>
  {
       localStorage.getItem("token") == null ? (
        (
          <div className='responsive-date d-flex justify-content-between align-items-center mt-5  w-100'>
          <h3>{t('log_first')}</h3>
          <div>
          <Link to='/login' className='btn btn-warning'>{t('login')}</Link>
          <Link to='/register' className='btn btn-warning mx-3'>{t('Register')}</Link>
            </div>
          </div>
        )
    )  : (
      
          (
            <div className='mt-5'>
            <div className="pickUp d-flex  justify-content-around align-items-center mt-5 busme">
            <div >
            <h2 className='px-3'>{t("pick_up")} :</h2>
            <select className='p-3 inputbus' id='checky' style={{width : "270px"}} onChange={(e)=>{setPick(e.target.value) 
              select()
              } }>
            <option value="Choose your pick up">{t('Choose_pick_up')}</option>
            <option value={localStorage.getItem('hotel')}>{t('Hotel')}</option>
            <option value="airport french side">{t('Aéroport_côté_Français')}</option>
            <option value="airport suisse side">{t('Airport_Swiss_side')}</option>
            <option value="Cornavin Train Station">{t('Cornavin_Train')}</option>
            <option value="Palexpo Arena">Palexpo Arena</option>
            <option value="United Nations ( UN )">{t('United_Nations')}</option>
            <option value="WHO ( World Health Organisation )">{t('WHO')}</option>
            <option value="WTO ( World Trade Organisation )">{t('wto')}</option>
            <option value="ITU ( International Telecommunication Union )">{t('itu')}</option>
            <option value="Quai Wilson Geneva">{t('quai')}</option>
            <option value="Quai de Mont Blanc Geneva">{t('quai_Mont')}</option>
            <option value="Geneva Old City">{t('old')}</option>
            <option value="Hospital de la Tour">{t('Hospital')}</option>
            <option value="Hospital HUG ( Hôpital Universitaire de Genève )">{t("HUG")}</option>
            <option value="Annecy">Annecy</option>
            <option value="Annemasse">Annemasse</option>
            <option value="CERN Prevessin">{t('cern')}</option>
            <option value="CERN Meyrin">CERN Meyrin</option>
            <option value="Val Thoiry">Val Thoiry</option>
            <option value="Bellegarde sur Valserine Train Station">{t('Bellegarde')}</option>
            <option value="Tag Aviation">Tag Aviation</option>
            <option value="Jet Aviation">Jet Aviation</option>
            <option value="Signature Aviation">Signature Aviation</option>
            <option value="Dassault Aviation">Dassault Aviation</option>
            </select>
            </div>
            <div className="logo text-center mt-5">
            <Card.Title className='m-0 h2'><BiTaxi /></Card.Title>
              <Card.Title className='mt-3 profile' id='myImage' onClick={()=>revert()}><IoMdSwap/></Card.Title>
            </div>
            <div className="pickUp  justify-content-center align-items-center">
            <h2 className='px-3'>Destination:</h2>
            <select className='p-3 inputbus' id='check' style={{width : "270px"}}
              onChange={(e)=>{
              setDrop(e.target.value)
            
              }}>
            <option>Kindly choose your pick up</option>
            </select>
           </div>
            </div>
            <FormGroup >
           <FormControlLabel className='justify-content-center mt-3' control={<Checkbox  onChange={(e)=>localStorage.setItem('ski' ,e.target.checked)}/>} label="Ski Matrial" />
            </FormGroup>
        </div>
        
          )

      )
    
  }
  <div className='btns'>
    
  <Link to="/" className='btn btn-primary mt-5 px-3 text-white '>{t('Back')}</Link>
  {
     localStorage.getItem("token") == null ? ('') : (
      (
        <>
        {
          loading == true ? <CircularProgress /> : 
          <button onClick={taxiData} className='btn btn-primary mt-5 px-3 text-white'>{t("Next")}</button>
        }
        
        </>
      )
     )
  }

 
 </div>
 <div className='h3 text-danger mt-5'>{me}</div>
 </Fade>
  </div>
  )
}
