import React from 'react'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useState } from 'react';
import { useTranslation} from "react-i18next";
import i18next from 'i18next';
import Table from './Table';
import TableUpcoming from './TableUpcoming';
import TableHistory from './TableHistory';




export default function Trips() {

    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

 

  let {t} = useTranslation()

  return (
    <div className='mt-5'>

<Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider'  }}>
          <TabList onChange={handleChange} className="center">
            <Tab label={t('All_Trips')} value="1" />
            <Tab label={t('Upcoming_Trips')} value="2" />
            <Tab label={t('History')} value="3" />
          </TabList>
        </Box>
        <TabPanel value="1"><Table /></TabPanel>
        <TabPanel value="2"><TableUpcoming /></TabPanel>
        <TabPanel value="3"><TableHistory /></TabPanel>
      </TabContext>
    </Box>

    </div>
  )
}
