let hotelName = window.localStorage.getItem("hotel");

export const taxiBookingData = [
  {value: hotelName, name:"My Hotel" },
  {value:"airport french side" , name:"airport french side" },
  {value:"airport suisse side" , name:"airport suisse side" },
  {value:"Cornavin Train Station" , name:"Cornavin Train Station" },
  {value:"Palexpo Arena" , name:"Palexpo Arena" },
  {value:"United Nations ( UN )" , name:"United Nations ( UN )" },
  {value:"WHO ( World Health Organisation )" , name:"WHO ( World Health Organisation )" },
  {value:"WTO ( World Trade Organisation )" , name:"WTO ( World Trade Organisation )" },
  {value:"ITU ( International Telecommunication Union )" , name:"ITU ( International Telecommunication Union )" },
  {value:"Quai Wilson Geneva" , name:"Quai Wilson Geneva" },
  {value:"Geneva Old City" , name:"Geneva Old City" },
  {value:"Hospital de la Tour" , name:"Hospital de la Tour" },
  {value:"Hospital HUG ( Hôpital Universitaire de Genève )" , name:"Hospital HUG ( Hôpital Universitaire de Genève )" },
  {value:"Annecy" , name:"Annecy" },
  {value:"Annemasse" , name:"Annemasse" },
  {value:"CERN Prevessin" , name:"CERN Prevessin" },
  {value:"Val Thoiry" , name:"Val Thoiry" },
  {value:"Bellegarde sur Valserine Train Station" , name:"Bellegarde sur Valserine Train Station" },
  {value:"Tag Aviation" , name:"Tag Aviation" },
  {value:"Signature Aviation" , name:"Signature Aviation" },
  {value:"Dassault Aviation" , name:"Dassault Aviation" },
]