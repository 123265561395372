import React from "react";
import { FaShuttleVan, FaTaxi } from "react-icons/fa";
import { BsFillPencilFill, BsPencilSquare } from "react-icons/bs";
import { Link } from "react-router-dom";
import Contacts from "./Contacts";
import { useTranslation } from "react-i18next";

export default function Buttons() {
  let { t } = useTranslation();
  let taxi = localStorage.getItem("taxi");
  let shuttle = localStorage.getItem("shuttleShow");
  return (
    <div>
      {localStorage.getItem("token") ? (
        <div className="parent_card">
          {shuttle === "true" ? (
            <Link to="/search" className="home_card">
              <div>
                <FaShuttleVan className="card_icon" />
                {t("Shuttle_service")}
              </div>
            </Link>
          ) : (
            ""
          )}

          {taxi === "true" ? (
            <Link to="/TaxiBooking" className="home_card">
              <div>
                <FaTaxi className="card_icon" />
                {t("Taxi_Service")}
              </div>
            </Link>
          ) : (
            ""
          )}
          <Link to="prices" className="home_card">
            <div>
              <BsPencilSquare className="card_icon" />
              {t("request")}
            </div>
          </Link>
          <Link to="/review" className="home_card">
            <div>
              <BsFillPencilFill className="card_icon" />
              {t("Reviews")}
            </div>
          </Link>
        </div>
      ) : (
        <div className="parent_card">
          {/* Shuttle_service */}
          <Link to="/search" className="home_card">
            <div>
              <FaShuttleVan className="card_icon" />
              {t("Shuttle_service")}
            </div>
          </Link>
          {/* Taxi Services */}
          <Link to="/TaxiSearch" className="home_card">
            <div>
              <FaTaxi className="card_icon" />
              {t("Taxi_Service")}
            </div>
          </Link>

          {/* Ride Requests */}
          <Link to="prices" className="home_card">
            {" "}
            <div>
              <BsPencilSquare className="card_icon" />
              {t("request")}
            </div>
          </Link>
          {/* review */}
          <Link to="/review" className="home_card">
            {" "}
            <div>
              <BsFillPencilFill className="card_icon" />
              {t("Reviews")}
            </div>
          </Link>
        </div>
      )}

      <Contacts />
    </div>
  );
}
