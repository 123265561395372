/* eslint-disable no-mixed-operators */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import "./taxiBooking.css";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { MdLocalTaxi, MdLuggage } from "react-icons/md";
import { toast } from "react-hot-toast";
import MovingText from "react-moving-text";
import {
  AiOutlineMinusCircle,
  AiOutlinePlusCircle,
  AiOutlineUser,
} from "react-icons/ai";
import { FaBaby, FaChild, FaDog, FaCat, FaSkiingNordic } from "react-icons/fa";
import { BsHandbag } from "react-icons/bs";
import { GiLightBackpack } from "react-icons/gi";
import { taxiBookingData } from "./taxiBookingData";
import { CircularProgress } from "@mui/material";
import CustomModal from "./CustomModal";
import { IoCloseCircleOutline } from "react-icons/io5";

const style = {
  position: "absolute",
  top: "50%",
  left: "20%",
  // transform: 'translate(-50%, -50%)',
  width: "60%",
  bgcolor: "white",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function TaxiBooking() {
  const token = localStorage.getItem("token");
  const [selectedOptionPickupFrom, setSelectedOptionPickUpFrom] = useState("");
  const [selectedOptionTypeVehicle, setSelectedOptionTypeVehicle] =
    useState("sedan");
  const [selectedOptionDestination, setSelectedOptionDestination] =
    useState("");

  const [
    dataFromDataBaseWhenEnterFromAndTo,
    setDataFromDataBaseWhenEnterFromAndTo,
  ] = useState({});

  const [adultsValue, setAdultsValue] = useState(0);
  const [infantValue, setInfantValue] = useState(0);
  const [childValue, setChildValue] = useState(0);

  const [catsValue, setCatsValue] = useState(0);
  const [dogsValue, setDogsValue] = useState(0);
  const [smallValue, setSmallValue] = useState(0);
  const [meduimValue, setMeduimValue] = useState(0);
  const [bigValue, setBigValue] = useState(0);

  const [nameValue, setNameValue] = useState("");
  const [dataValue, setDataValue] = useState("");
  const [timeValue, setTimeValue] = useState("");
  const [flight_number, setFlight_number] = useState("");
  const [room_number, setRoom_number] = useState("");

  const [loadingWhenRequestPendding, setLoadingWhenRequestPendding] =
    useState();

  const [shwoNumberRoom, setShwoNumberRoom] = useState(false);
  const [shwoNumberFlight, setShwoNumberFlight] = useState(false);
  const [showFormDiv, setShowFormDiv] = useState(false);

  // confirm booking modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Start Adult
  const handleMinusAdult = (e) => {
    e.preventDefault();
    if (adultsValue <= 0) {
      console.log("nagitave");
    } else {
      setAdultsValue((prev) => prev - 1);
    }
  };
  const handlePlusAdult = (e) => {
    e.preventDefault();
    if (selectedOptionTypeVehicle == "sedan") {
      if (adultsValue >= 4) {
        console.log("No More");
      } else {
        setAdultsValue((prev) => prev + 1);
      }
    } else {
      if (adultsValue >= 7) {
        console.log("No More");
      } else {
        setAdultsValue((prev) => prev + 1);
      }
    }
  };
  // End Adult

  // Start Infant
  const handleMinusInfant = (e) => {
    e.preventDefault();
    if (infantValue <= 0) {
      console.log("nagitave");
    } else {
      setInfantValue((prev) => prev - 1);
    }
  };
  const handlePlusInfant = (e) => {
    e.preventDefault();
    if (selectedOptionTypeVehicle == "sedan") {
      if (infantValue >= 4) {
        console.log("No More");
      } else {
        setInfantValue((prev) => prev + 1);
      }
    } else {
      if (infantValue >= 7) {
        console.log("No More");
      } else {
        setInfantValue((prev) => prev + 1);
      }
    }
  };
  // End Infant

  // Start Child
  const handleMinusChild = (e) => {
    e.preventDefault();
    if (childValue <= 0) {
      console.log("nagitave");
    } else {
      setChildValue((prev) => prev - 1);
    }
  };
  const handlePlusChild = (e) => {
    e.preventDefault();
    if (selectedOptionTypeVehicle == "sedan") {
      if (childValue >= 4) {
        console.log("No More");
      } else {
        setChildValue((prev) => prev + 1);
      }
    } else {
      if (childValue >= 7) {
        console.log("No More");
      } else {
        setChildValue((prev) => prev + 1);
      }
    }
  };
  // End Child

  /****************** Start luggages *********************** */
  // Start Adult
  const handleMinusSmall = (e) => {
    e.preventDefault();
    if (smallValue <= 0) {
      console.log("nagitave");
    } else {
      setSmallValue((prev) => prev - 1);
    }
  };
  const handlePlusSmall = (e) => {
    e.preventDefault();
    setSmallValue((prev) => prev + 1);
  };
  // End Adult

  // Start Infant
  const handleMinusMeduim = (e) => {
    e.preventDefault();
    if (meduimValue <= 0) {
      console.log("nagitave");
    } else {
      setMeduimValue((prev) => prev - 1);
    }
  };
  const handlePlusMeduim = (e) => {
    e.preventDefault();
    setMeduimValue((prev) => prev + 1);
  };
  // End Infant

  // Start Child
  const handleMinusBig = (e) => {
    e.preventDefault();
    if (bigValue <= 0) {
      console.log("nagitave");
    } else {
      setBigValue((prev) => prev - 1);
    }
  };
  const handlePlusBig = (e) => {
    e.preventDefault();
    setBigValue((prev) => prev + 1);
  };
  // End Child

  /// start cats

  const handleMinusCats = (e) => {
    e.preventDefault();
    if (catsValue <= 0) {
      console.log("nagitave");
    } else {
      setCatsValue((prev) => prev - 1);
    }
  };
  const handlePlusCats = (e) => {
    e.preventDefault();
    setCatsValue((prev) => prev + 1);
  };
  // End cats

  // Start dogs
  const handleMinusDogs = (e) => {
    e.preventDefault();
    if (dogsValue <= 0) {
      console.log("nagitave");
    } else {
      setDogsValue((prev) => prev - 1);
    }
  };
  const handlePlusDogs = (e) => {
    e.preventDefault();
    setDogsValue((prev) => prev + 1);
  };
  // End dogs

  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
  useEffect(() => {
    if (selectedOptionPickupFrom == localStorage.getItem("hotel")) {
      setShwoNumberRoom(true);
      setShwoNumberFlight(false);
    } else {
      setShwoNumberRoom(false);
      setShwoNumberFlight(true);
    }

    // handleSeacrhWithDateFromTo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptionPickupFrom, selectedOptionDestination]);

  const handleSeacrhWithDateFromTo = () => {
    if (
      selectedOptionPickupFrom == "" ||
      selectedOptionPickupFrom == "em" ||
      selectedOptionDestination == "" ||
      selectedOptionDestination == "em"
    ) {
      // toast.error("Enter Pick up And Destination");
    } else {
      fetch(
        `https://kiro-travel.herokuapp.com/api/v1/shuttle/taxi_list/?pickup=${selectedOptionPickupFrom}&drop=${selectedOptionDestination}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then(function (response) {
          // console.log(response);
          setDataFromDataBaseWhenEnterFromAndTo(response[0]);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  // start handel ski matrial
  const [skiMaterial, setSkiMatrial] = useState(0);
  const handleMinusSki = ()=>{
    setSkiMatrial(prev =>{
      if(prev == 0) return prev
      return prev - 1
    })
  }
  const handlePlusSki = ()=>{
    setSkiMatrial(prev =>{
      if(prev == 4) return prev
      return prev + 1
    })
  }

  useEffect(()=>{
    if(skiMaterial > 2 && selectedOptionTypeVehicle == "sedan" || skiMaterial <= 2 && adultsValue + infantValue + childValue > 2){
      if(selectedOptionTypeVehicle == "sedan" ){
        toast.success("SELECTED CAR SWITCHED TO VAN We've changed your car selection to a van as the maximum sedan capacity is four passengers and 2 Skis, This ensures a comfortable trip.",
          {duration:"800"}
        )
      }
      setSelectedOptionTypeVehicle("van")
    }
    else{
      if(selectedOptionTypeVehicle == "van" ){
        toast.success("Selected Car Switched To SEDAN We've changed your car selection to a Sedan, This ensures a comfortable trip.",
          {duration:"800"}
        )
      }
      setSelectedOptionTypeVehicle("sedan")
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[skiMaterial , adultsValue , infantValue , childValue])
  
  const [payMethod, setPayMethod] = useState("");

  // Hadnel Response
  const [showModal, setShowModal] = useState(false);
  const handelToogel = () => setShowModal((prev) => !prev);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      new Date(dataValue).toLocaleDateString() ==
        new Date().toLocaleDateString() &&
      (new Date(`${dataValue}T${timeValue}`).getTime() - new Date().getTime()) /
        60000 <
        15
    ) {
      // if(((new Date(`${dataValue}T${timeValue}`).getTime()-new Date().getTime())/60000)<15){

      toast.error(
        "Can’t Book A a taxi Within Less than 15 Minutes Try choosing another Time"
      );
      // }
    } else {
      setOpen(true);
    }
    // setShowFormDiv(false);

    // const reqObject = {
    //   vechile: selectedOptionTypeVehicle,
    //   user: localStorage.getItem("user_id"),
    //   taxi: dataFromDataBaseWhenEnterFromAndTo?.id,
    //   backpack: bigValue,
    //   handbag: smallValue,
    //   big_luggage: meduimValue,
    //   enfant: infantValue,
    //   child: childValue,
    //   adult: adultsValue,
    //   flight_number: flight_number,
    //   room_number: room_number,
    //   full_name: nameValue,
    //   date: dataValue,
    //   time: timeValue,
    //   payment_method: payMethod,
    //   ski_material: skiMaterial,
    // };
    // console.log(reqObject);
    // let requestOptions = {
    //   method: "POST",
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(reqObject),
    // };
    // if (
    //   adultsValue + infantValue + childValue >= 4 &&
    //   selectedOptionTypeVehicle == "sedan"
    // ) {
    //   toast.error("you can select maximum 4 person or you can select van");
    // } else if (
    //   adultsValue + infantValue + childValue >= 6 &&
    //   selectedOptionTypeVehicle == "van"
    // ) {
    //   toast.error("you can select maximum 6 person");
    // } else {
    //   setLoadingWhenRequestPendding(true);
    //   fetch(
    //     `https://kiro-travel.herokuapp.com/api/v1/shuttle/taxi_book/`,
    //     requestOptions
    //   )
    //     .then((res) => res.json())
    //     .then((response) => {
    //       console.log(response);
    //       if (response.success) {
    //         if (payMethod == "Visa") {
    //           window.open(response.data.payment_link, "_blank").focus();
    //         } else setShowModal(true);
    //       } else toast.error("Somthing Wrong Tray Again Later");
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //       toast.error("Somthing Wrong Tray Again Later");
    //     })
    //     .finally(() => setLoadingWhenRequestPendding(false));
    // }
  };

  const confirmBookingFunc = () => {
    setShowFormDiv(false);
    setOpen(false);
    const reqObject = {
      vechile: selectedOptionTypeVehicle,
      user: localStorage.getItem("user_id"),
      taxi: dataFromDataBaseWhenEnterFromAndTo?.id,
      backpack: bigValue,
      handbag: smallValue,
      big_luggage: meduimValue,
      enfant: infantValue,
      child: childValue,
      adult: adultsValue,
      cats: catsValue,
      dogs: dogsValue,
      flight_number: flight_number,
      room_number: room_number,
      full_name: nameValue,
      date: dataValue,
      time: timeValue,
      payment_method: payMethod,
      ski: skiMaterial,
    };
    // console.log(reqObject);
    let requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reqObject),
    };
    if (
      adultsValue + infantValue + childValue >= 4 &&
      selectedOptionTypeVehicle == "sedan"
    ) {
      toast.error("you can select maximum 4 person or you can select van");
    } else if (
      adultsValue + infantValue + childValue >= 6 &&
      selectedOptionTypeVehicle == "van"
    ) {
      toast.error("you can select maximum 6 person");
    } else {
      setLoadingWhenRequestPendding(true);
      fetch(
        `https://kiro-travel.herokuapp.com/api/v1/shuttle/taxi_book/`,
        requestOptions
      )
        .then((res) => res.json())
        .then((response) => {
          console.log(response);
          if (response.success) {
            if (payMethod == "Visa") {
              window.open(response?.data.payment_link, "_blank").focus();
            } else {
              setShowModal(true);
            }

            setSelectedOptionPickUpFrom("");
            setSelectedOptionDestination("");
            setAdultsValue(0);
            setInfantValue(0);
            setChildValue(0);

            setCatsValue(0);
            setDogsValue(0);
            setSmallValue(0);
            setMeduimValue(0);
            setBigValue(0);

            setNameValue("");
            setDataValue("");
            setTimeValue("");
            setFlight_number("");
            setRoom_number("");
            setSkiMatrial(false);
            setPayMethod("");
          } else toast.error("Somthing Wrong Try Again Later");
        })
        .catch(function (error) {
          // console.log(error);
          toast.error("Somthing Wrong Try Again Later");
        })
        .finally(() => setLoadingWhenRequestPendding(false));
    }
  };
  // Start Handel Selection
  const [pickUpData, setPickUpData] = useState(taxiBookingData);
  const [destinationData, setDestinationData] = useState([]);
  useEffect(() => {
    setPickUpData([
      { value: localStorage.getItem("hotel"), name: "My Hotel" },
      { value: "airport french side", name: "airport french side" },
      { value: "airport suisse side", name: "airport suisse side" },
      { value: "Cornavin Train Station", name: "Cornavin Train Station" },
      { value: "Palexpo Arena", name: "Palexpo Arena" },
      { value: "United Nations ( UN )", name: "United Nations ( UN )" },
      {
        value: "WHO ( World Health Organisation )",
        name: "WHO ( World Health Organisation )",
      },
      {
        value: "WTO ( World Trade Organisation )",
        name: "WTO ( World Trade Organisation )",
      },
      {
        value: "Zenitude Hôtel-Résidences - Ferney Voltaire",
        name: "Zenitude Hôtel-Résidences - Ferney Voltaire",
      },
      {
        value: "APPART&#x27;CITY CONFORT Genève Aéroport Ferney Voltaire, France",
        name: "APPART'CITY CONFORT Genève Aéroport Ferney Voltaire, France",
      },
      {
        value: "ITU ( International Telecommunication Union )",
        name: "ITU ( International Telecommunication Union )",
      },
      { value: "Quai Wilson Geneva", name: "Quai Wilson Geneva" },
      { value: "Geneva Old City", name: "Geneva Old City" },
      { value: "Hospital de la Tour", name: "Hospital de la Tour" },
      {
        value: "Hospital HUG ( Hôpital Universitaire de Genève )",
        name: "Hospital HUG ( Hôpital Universitaire de Genève )",
      },
      { value: "Annecy", name: "Annecy" },
      { value: "Annemasse", name: "Annemasse" },
      { value: "CERN Prevessin", name: "CERN Prevessin" },
      { value: "Val Thoiry", name: "Val Thoiry" },
      {
        value: "Bellegarde sur Valserine Train Station",
        name: "Bellegarde sur Valserine Train Station",
      },
      { value: "Tag Aviation", name: "Tag Aviation" },
      { value: "Signature Aviation", name: "Signature Aviation" },
      { value: "Dassault Aviation", name: "Dassault Aviation" },
    ]);
  }, [localStorage.getItem("hotel")]);

  const handelSelecthion = (value) => {
    const hotelName = localStorage.getItem("hotel");

    console.log("hotel", hotelName);
    console.log("pickup", pickUpData);
    if (value == hotelName) {
      const newArr = pickUpData.filter((i) => i.value !== hotelName);
      setDestinationData(newArr);
    } else {
      const newArr = pickUpData.filter((i) => i.value == hotelName);
      setDestinationData(newArr);
    }
  };

  /// confirm pickup and destination

  const confirmDestination = () => {
    console.log("dest", selectedOptionPickupFrom, selectedOptionDestination);
    if (
      selectedOptionPickupFrom == "" ||
      selectedOptionPickupFrom == "em" ||
      selectedOptionDestination == "" ||
      selectedOptionDestination == "em"
    ) {
      toast.error("kindly choose destination");
    } else {
      handleSeacrhWithDateFromTo();
      setShowFormDiv(true);
    }

    console.log("confirmed");
    //  window.scrollTo(0, 500)
  };


  // Opration Time 
  const timeString = timeValue;
  const [hoursString, minutesString] = timeString.split(":");
  let hours = parseInt(hoursString);
  let meridiem = "AM";
  if (hours > 12) {
    hours -= 12;
    meridiem = "PM";
  }
  const time12hString = `${hours}:${minutesString} ${meridiem}`;

  return (
    <>
      <section>

        {/****header Div**** */}

        <div className="taxiBookingBg">

          <div className="headerInputsDiv">
            <div className="destinationFrom">
              <label htmlFor="my-select">Pick up From :</label>
              <select
                required
                value={selectedOptionPickupFrom}
                onChange={(e) => {
                  console.log(e.target.value);
                  setSelectedOptionPickUpFrom(e.target.value);
                  handelSelecthion(e.target.value);
                }}
                className="styleDateInputSelect"
                style={{ width: "100%" }}
              >
                <option value="" disabled selected>
                  Choose your pick up
                </option>
                {pickUpData.length > 0 &&
                  pickUpData.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>

            {/* Vicle */}
            <div className="vehicle">
              <label htmlFor="my-select">Type Vehicle:</label>
              <select
                required
                value={selectedOptionTypeVehicle}
                onChange={(e) => setSelectedOptionTypeVehicle(e.target.value)}
                className="styleDateInputSelect"
              >
                <option value="sedan">Sedan</option>
                <option value="van">Van</option>
              </select>
            </div>

            {/* Destination  */}
            <div className="destinationTo">
              <label htmlFor="my-select">Destination:</label>
              <select
                required
                value={selectedOptionDestination}
                onChange={(e) => setSelectedOptionDestination(e.target.value)}
                className="styleDateInputSelect"
              >
                <option value="" disabled selected>
                  Choose your destination
                </option>
                {destinationData.length > 0 &&
                  destinationData.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>

            {/* <div > */}
            <button className="confirmBtn" onClick={confirmDestination}>
              Confirm
            </button>
            {/* </div> */}
          </div>
        </div>
        {/********* */}
        {showFormDiv ? (
          <form className="parentTaxi" onSubmit={handleSubmit}>
            <div>
              <div className="parentInputs">
                <div className="parentShuttleBooking">
                  <div className="taxiParentLayOut">
                    <div className="layOutFormTaxi">
                      {/* <div className="styleInput start">
                      <label htmlFor="my-select">Pick up From :</label>
                      <select
                        required
                        value={selectedOptionPickupFrom}
                        onChange={(e) => {
                          setSelectedOptionPickUpFrom(e.target.value);
                          handelSelecthion(e.target.value);
                        }}
                        className="styleDateInputSelect taxistyleInput equalInputName"
                      >
                        <option value="" disabled selected>
                          Choose your pick up
                        </option>
                        {pickUpData.length > 0 &&
                          pickUpData.map((item) => (
                            <option key={item.value} value={item.value}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                    </div> */}

                      {/* Vicle */}
                      {/* <div className="styleInput centerdiv">
                      <label htmlFor="my-select">Type Vehicle:</label>
                      <select
                        required
                        value={selectedOptionTypeVehicle}
                        onChange={(e) =>
                          setSelectedOptionTypeVehicle(e.target.value)
                        }
                        className="styleDateInputSelect taxistyleInputVich"
                      >
                        <option value="sedan">Sedan</option>
                        <option value="van">Van</option>
                      </select>
                    </div> */}

                      {/* Destination  */}
                      {/* <div className="styleInput end">
                      <label htmlFor="my-select">Destination:</label>
                      <select
                        required
                        value={selectedOptionDestination}
                        onChange={(e) =>
                          setSelectedOptionDestination(e.target.value)
                        }
                        className="styleDateInputSelect taxistyleInput"
                      >
                        <option value="" disabled selected>
                          Choose your destination
                        </option>
                        {destinationData.length > 0 &&
                          destinationData.map((item) => (
                            <option key={item.value} value={item.value}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Form Booking  */}
            <MovingText
              type="fadeInFromLeft"
              duration="2000ms"
              delay="0s"
              direction="normal"
              timing="ease-in-out"
              iteration="1"
              fillMode="none"
              //style={{width: "80%"}}
              className="animate-div"
            >
              <div className="parentBooking">
                <div style={{ width: "100%", margin: "auto" }}>
                  <div className="layOutBooking">
                    {/* Left Input  */}
                    <div>
                      <div className="layOutInputBooking">
                        <label htmlFor="my-select">Name:</label>
                        <input
                          required
                          value={nameValue}
                          onChange={(e) => setNameValue(e.target.value)}
                          type="text"
                          placeholder="Enter You Name"
                          className="bookingstyleInput"
                        />
                      </div>

                      <div className="layOutInputBooking">
                        <label htmlFor="my-select">Date:</label>
                        <input
                          required
                          value={dataValue}
                          onChange={(e) => {
                            setDataValue(e.target.value);
                            console.log(
                              "date",
                              new Date(e.target.value).toLocaleDateString() ==
                                new Date().toLocaleDateString()
                            );
                          }}
                          type="date"
                          placeholder="Enter Date"
                          className="bookingstyleInput"
                        />
                      </div>

                      <div className="layOutInputBooking">
                        <label htmlFor="my-select">Time:</label>
                        <input
                          required
                          value={timeValue}
                          onChange={(e) => {
                            setTimeValue(e.target.value);
                            console.log("time", e);
                          }}
                          type="time"
                          placeholder="Enter Time"
                          className="bookingstyleInput"
                        />
                      </div>

                      {shwoNumberRoom && (
                        <div className="layOutInputBooking">
                          <label htmlFor="my-select">Room Number:</label>
                          <input
                            required
                            value={room_number}
                            onChange={(e) => setRoom_number(e.target.value)}
                            type="number"
                            placeholder="Enter Room Number"
                            className="bookingstyleInput"
                          />
                        </div>
                      )}

                      {shwoNumberFlight && (
                        <div className="layOutInputBooking">
                          <label htmlFor="my-select">Flight Number:</label>
                          <input
                            required
                            value={flight_number}
                            onChange={(e) => setFlight_number(e.target.value)}
                            type="number"
                            placeholder="Enter Flight Number"
                            className="bookingstyleInput"
                          />
                        </div>
                      )}
                      <div className="layOutInputBooking">
                        <label>Payment Way :</label>
                        <select
                          required
                          value={payMethod}
                          onChange={(e) => {
                            console.log(e.target.value);
                            setPayMethod(e.target.value);
                          }}
                          className="styleDateInputSelect taxistyleInput equalInputName rounded"
                        >
                          <option value="" disabled selected>
                            Choose your Payment Method
                          </option>
                          <option value="Visa">Payment With Visa</option>
                          <option value="Cash">Payment With Cash</option>
                        </select>
                      </div>

                      {/* <div className="skiMatrual_btn">
                        <label htmlFor="ski-matrial">Ski Matrial</label>
                        <input
                          id="ski-matrial"
                          // onChange={(e) => setSkiMatrial((prev) => !prev)}
                          onChange={(e) => setSkiMatrial(e.target.checked)}
                          type="checkbox"
                        />
                      </div> */}

                      <div className="layoutLeftAndRight mt-4 mb-3">
                        <div className="leftBooking">
                          <div>
                            <FaSkiingNordic size={25}/>
                            {/* <FaCat  /> */}
                          </div>
                          <h5 className="m-0">Ski Matrial</h5>
                        </div>
                        <div className="rightBooking">
                          <div
                            onClick={(e) => handleMinusSki(e)}
                            className="ico"
                          >
                            <AiOutlineMinusCircle size={30} />
                          </div>
                          <div className="w">{skiMaterial}</div>
                          <div
                            onClick={(e) => handlePlusSki(e)}
                            className="ico"
                          >
                            <AiOutlinePlusCircle size={30} />
                          </div>
                        </div>
                      </div>
                    
                    </div>
                    {/* Center Input  */}
                    <div className="parentInOrDec">
                      {/* Start Passenger  */}
                      <div className="layOutParentInOrDec">
                        <div className="titleThis">Passengers</div>
                        <div className="layoutLeftAndRight">
                          <div className="leftBooking">
                            <div>
                              <AiOutlineUser size={30} />
                            </div>
                            <h3>Adults</h3>
                          </div>
                          <div className="rightBooking">
                            <div
                              onClick={(e) => handleMinusAdult(e)}
                              className="ico"
                            >
                              <AiOutlineMinusCircle size={30} />
                            </div>
                            <div className="w">{adultsValue}</div>
                            <div
                              onClick={(e) => handlePlusAdult(e)}
                              className="ico"
                            >
                              <AiOutlinePlusCircle size={30} />
                            </div>
                          </div>
                        </div>

                        <div className="layoutLeftAndRight">
                          <div className="leftBooking">
                            <div>
                              <FaBaby size={30} />
                            </div>
                            <h3>Infant</h3>
                          </div>
                          <div className="rightBooking">
                            <div
                              onClick={(e) => handleMinusInfant(e)}
                              className="ico"
                            >
                              <AiOutlineMinusCircle size={30} />
                            </div>
                            <div className="w">{infantValue}</div>
                            <div
                              onClick={(e) => handlePlusInfant(e)}
                              className="ico"
                            >
                              <AiOutlinePlusCircle size={30} />
                            </div>
                          </div>
                        </div>

                        <div className="layoutLeftAndRight">
                          <div className="leftBooking">
                            <div>
                              <FaChild size={30} />
                            </div>
                            <h3>Child</h3>
                          </div>
                          <div className="rightBooking">
                            <div
                              onClick={(e) => handleMinusChild(e)}
                              className="ico"
                            >
                              <AiOutlineMinusCircle size={30} />
                            </div>
                            <div className="w">{childValue}</div>
                            <div
                              onClick={(e) => handlePlusChild(e)}
                              className="ico"
                            >
                              <AiOutlinePlusCircle size={30} />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* End Passenger  */}

                      {/* Start luggages  */}
                      <div className="layOutParentInOrDec">
                        <div className="titleThis">Luggages</div>
                        <div className="layoutLeftAndRight">
                          <div className="leftBooking">
                            <div>
                              <GiLightBackpack size={30} />
                            </div>
                            <h3>Small</h3>
                          </div>
                          <div className="rightBooking">
                            <div
                              onClick={(e) => handleMinusSmall(e)}
                              className="ico"
                            >
                              <AiOutlineMinusCircle size={30} />
                            </div>
                            <div className="w">{smallValue}</div>
                            <div
                              onClick={(e) => handlePlusSmall(e)}
                              className="ico"
                            >
                              <AiOutlinePlusCircle size={30} />
                            </div>
                          </div>
                        </div>

                        <div className="layoutLeftAndRight">
                          <div className="leftBooking">
                            <div>
                              <BsHandbag size={30} />
                            </div>
                            <h3>Meduim</h3>
                          </div>
                          <div className="rightBooking">
                            <div
                              onClick={(e) => handleMinusMeduim(e)}
                              className="ico"
                            >
                              <AiOutlineMinusCircle size={30} />
                            </div>
                            <div className="w">{meduimValue}</div>
                            <div
                              onClick={(e) => handlePlusMeduim(e)}
                              className="ico"
                            >
                              <AiOutlinePlusCircle size={30} />
                            </div>
                          </div>
                        </div>

                        <div className="layoutLeftAndRight">
                          <div className="leftBooking">
                            <div>
                              <MdLuggage size={30} />
                            </div>
                            <h3>Big</h3>
                          </div>
                          <div className="rightBooking">
                            <div
                              onClick={(e) => handleMinusBig(e)}
                              className="ico"
                            >
                              <AiOutlineMinusCircle size={30} />
                            </div>
                            <div className="w">{bigValue}</div>
                            <div
                              onClick={(e) => handlePlusBig(e)}
                              className="ico"
                            >
                              <AiOutlinePlusCircle size={30} />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* End luggages  */}
                      {/* Start Pets  */}
                      <div className="layOutParentInOrDec">
                        <div className="titleThis">Pets</div>
                        <div className="layoutLeftAndRight">
                          <div className="leftBooking">
                            <div>
                              <FaCat size={30} />
                            </div>
                            <h3>Cats</h3>
                          </div>
                          <div className="rightBooking">
                            <div
                              onClick={(e) => handleMinusCats(e)}
                              className="ico"
                            >
                              <AiOutlineMinusCircle size={30} />
                            </div>
                            <div className="w">{catsValue}</div>
                            <div
                              onClick={(e) => handlePlusCats(e)}
                              className="ico"
                            >
                              <AiOutlinePlusCircle size={30} />
                            </div>
                          </div>
                        </div>

                        <div className="layoutLeftAndRight">
                          <div className="leftBooking">
                            <div>
                              <FaDog size={30} />
                            </div>
                            <h3>Dogs</h3>
                          </div>
                          <div className="rightBooking">
                            <div
                              onClick={(e) => handleMinusDogs(e)}
                              className="ico"
                            >
                              <AiOutlineMinusCircle size={30} />
                            </div>
                            <div className="w">{dogsValue}</div>
                            <div
                              onClick={(e) => handlePlusDogs(e)}
                              className="ico"
                            >
                              <AiOutlinePlusCircle size={30} />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* End Pets */}
                    </div>
                  </div>

                  {/* Buttons Submite And Print  */}
                  <div className="parentButtons">
                    <button
                      disabled={loadingWhenRequestPendding}
                      type="submit"
                      className="sub buttonSumbiteBooking"
                    >
                      {loadingWhenRequestPendding && (
                        <CircularProgress size={18} color="inherit" />
                      )}
                      {!loadingWhenRequestPendding && <>Book Now</>}
                    </button>
                  </div>
                </div>
              </div>
            </MovingText>
          </form>
        ) : null}
      </section>

      <CustomModal isOpen={showModal} handelToogel={handelToogel}>
        <div className="parentModalSuceess">
          <button onClick={() => handelToogel()} className="closeBtn">
            <IoCloseCircleOutline size={30} />
          </button>
          <img src="/success.gif" alt="success" className="" />
          <h3 className="text-center">Booking Confirmed</h3>
          <p className="w-75 text-center">
            Your Taxi Booking Had Been Confirmed Successfully , You Will Receive
            a Confirmation Mail Shortly
          </p>
        </div>
      </CustomModal>

      {/***confirm modal*** */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="ticketContain">
          <div
            style={{ width: "100%" }}
            className="parentTicket"
            id="printableArea"
          >
            <div className="layOutTicket">
              <div className="barcode">
                <div className="parentIage">
                  <img
                    src="https://www.freepnglogos.com/uploads/barcode-png/barcode-laser-code-vector-graphic-pixabay-3.png"
                    width={"100%"}
                    height={"100px"}
                    alt="card"
                  />
                </div>
                <div className="priceBooking">
                  <strong>
                    {selectedOptionTypeVehicle === "sedan"
                      ? dataFromDataBaseWhenEnterFromAndTo?.price_sedan
                      : dataFromDataBaseWhenEnterFromAndTo?.price_van}
                    €
                  </strong>{" "}
                </div>
              </div>
              <div className="dataOne">
                <div className="dataFinal">
                  <div className="Name">
                    <h4>Name Of Passenger</h4>
                    <p>{nameValue ? nameValue : "_"}</p>
                  </div>
                  <div className="Name">
                    <h4>car</h4>
                    <p>{selectedOptionTypeVehicle}</p>
                  </div>
                  <div className="Name">
                    <h4>Date</h4>
                    <p>{dataValue ? dataValue : "_"}</p>
                  </div>
                  <div className="Name">
                    <h4>Time</h4>
                    <p>{time12hString ? time12hString : "_"}</p>
                  </div>
                </div>

                <div className="parentFroTo">
                  <div className="parentFroToData">
                    <h1 title={selectedOptionPickupFrom}>
                      {selectedOptionPickupFrom
                        ? selectedOptionPickupFrom.slice(0, 19) + "..."
                        : "_"}
                    </h1>
                    <span>
                      <MdLocalTaxi size={50} />
                    </span>
                    <h1 title={selectedOptionDestination}>
                      {selectedOptionDestination
                        ? selectedOptionDestination.slice(0, 19) + "..."
                        : "_"}
                    </h1>
                  </div>
                </div>
              </div>
              <div className="dataTwo">
                <div className="parentImage">
                  <img
                    src="https://www.freepnglogos.com/uploads/barcode-png/barcode-laser-code-vector-graphic-pixabay-3.png"
                    width={"100%"}
                    height={"50px"}
                    alt="barcode"
                  />
                </div>
                <div className="dataRight">
                  <div className="Name">
                    <h5>Name</h5>
                    <p>{nameValue ? nameValue : "_"}</p>
                  </div>
                  <div className="Name">
                    <h5>car</h5>
                    <p>{selectedOptionTypeVehicle}</p>
                  </div>
                  <div className="Name">
                    <h5>Date</h5>
                    <p>{dataValue ? dataValue : "_"}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ padding: "1em", display: "flex", justifyContent: "end" }}
          >
            <button className="modalBack" onClick={handleClose}>
              Back
            </button>
            <button className="modalConfirm" onClick={confirmBookingFunc}>
              Confirm Booking
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default TaxiBooking;
