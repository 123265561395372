import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { Contextet } from "../Context/Context";
import Fade from "react-reveal/Fade";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function Bus() {
  let {
    busError,
    userError,
    setUserBusError,
    setBusError,
    form,
    setUserForm,
    userForm,
    depature,
    pick,
  } = useContext(Contextet);

  let { t } = useTranslation();

  let o = depature;

  useEffect(() => {
    return () => {
      setBusError("");
      localStorage.removeItem("ski");
    };
  }, []);

  function getDetils(e) {
    let data = { ...userForm };
    data[e.target.name] = e.target.value;
    setUserForm(data);
    let x = JSON.stringify(userForm);
    localStorage.setItem("user", x);
  }

  return (
    <>
      <Fade top>
        {
          (depature = null ? (
            <>
              <h2 className="m-auto">{t("shuttls")}</h2>
            </>
          ) : localStorage.getItem("token") == null ? (
            <div className="text-center">
              <div className="mt-2 d-flex justify-content-between align-items-center flex-wrap responsive-date  ">
                <h3>{t("log_first")}</h3>
                <div>
                  <Link to="/login" className="btn btn-warning">
                    {t("login")}
                  </Link>
                  <Link to="/register" className="btn btn-warning mx-3">
                    {t("Register")}
                  </Link>
                </div>
              </div>
              <Link to="/" className="btn btn-primary">
                {t("Back")}
              </Link>
            </div>
          ) : (
            <form
              className="d-flex flex-column p-4 mt-2"
              style={{
                backgroundColor: "white",
                boxShadow: "2px 4px 41px 18px rgba(0,0,0,0.35)",
              }}
            >
              <div className="p-5 bg-primary" style={{ borderRadius: "22px" }}>
                <h2 className="text-white text-center"> {t("info")} </h2>
              </div>
              <div className="d-flex flex-column">
                <div>
                  <input
                    type="text"
                    onChange={(e) => getDetils(e)}
                    name="first_name"
                    className="w-100 p-2 mt-5"
                    placeholder={t("enter_name")}
                  />
                </div>
                <div>
                  <input
                    type="text"
                    onChange={(e) => getDetils(e)}
                    name="last_name"
                    className="w-100 p-2 mt-5"
                    placeholder={t("enter_lname")}
                  />
                </div>
                <div>
                  <input
                    type="number"
                    onChange={(e) => getDetils(e)}
                    name="no_of_bags"
                    className="w-100 p-2 mt-5"
                    placeholder={t("baggage_weight")}
                  />
                </div>
                <div>
                  <input
                    type="number"
                    onChange={(e) => getDetils(e)}
                    name="room"
                    className="w-100 p-2 mt-5"
                    placeholder={t("enter_room")}
                  />
                </div>
                <div className="mt-5">
                  <input
                    type="number"
                    onChange={(e) => getDetils(e)}
                    name="seats_booked"
                    className="w-100 p-2"
                    placeholder={t("seats_number")}
                  />
                </div>
                <div>
                  <input
                    type="number"
                    onChange={(e) => getDetils(e)}
                    name="phone"
                    className="w-100 p-2 mt-5"
                    placeholder={t("enter_phone")}
                  />
                </div>

                {pick != localStorage.getItem("hotel") ? (
                  <div>
                    <input
                      type="text"
                      onChange={(e) => getDetils(e)}
                      name="flight"
                      className="w-100 p-2 mt-5"
                      placeholder={t("Flight")}
                    />
                  </div>
                ) : (
                  ""
                )}

                <div className="mt-3">
                  <h2>
                    {t("User_Name")}: {localStorage.getItem("username")}
                  </h2>
                </div>
              </div>
              <div className="buttons d-flex justify-content-center">
                <Link to="/bus">
                  <Button className=" mx-2 " variant="primary">
                    {t("Back")}
                  </Button>
                </Link>
                <Button
                  onClick={() => {
                    form();
                  }}
                  className="warning"
                  variant="warning"
                >
                  {t("send")}{" "}
                </Button>
              </div>

              <div className="text-danger text-center mt-3 h3">
                {userError} {busError}
              </div>
            </form>
          ))
        }
      </Fade>
    </>
  );
}
