import React, { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { Registert } from "../Context/RegisterContext";
import { useTranslation } from "react-i18next";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { BiHide, BiShowAlt } from "react-icons/bi";

export default function Register() {
  const [showPass, setShowPass] = useState(true)

  let { logs, getNewUser, error, value, setValue, setNewUser } =
    useContext(Registert);

  let { t } = useTranslation();

  return (
    <div className="login_LayOut">
      {localStorage.getItem("token") ? (
        <div className="text-center">
          <div className="h2 text-danger">{t("credintial")}</div>
          <Link to="/" className="btn btn-primary mt-3">
            {t("home")}
          </Link>
        </div>
      ) : (
        <>
        <div className='parentImageInReg'>
          <img alt="taxi" src='/reg.jpg' className="image_header"/>
        </div>
          <Form.Group className="mb-3" controlId="formBasicEmail">
                {/* <Form.Label>
                  {t("Phone_Number")}
                  <span className="text-danger h4">*</span> :
                </Form.Label> */}
            <div className='login_parent'>
              <PhoneInput
                defaultCountry="FR"
                className='inputLogin'
                placeholder="Enter Phone Number"
                value={value}
                onChange={setValue}
              />
            </div>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            {/* <Form.Label>
              {t("Email_Address")}
              <span className="text-danger h4">*</span> :
            </Form.Label> */}
            <div className='login_parent'>
              <Form.Control
                className='inputLogin'
                type="email"
                placeholder="Enter Email"
                name="email"
                onChange={getNewUser}
              />
            </div>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPasswords">
            {/* <Form.Label>
              {t("User_Name")}
              <span className="text-danger h4">*</span> :
            </Form.Label> */}
            <div className='login_parent'>
              <Form.Control
                className='inputLogin'
                type="text"
                placeholder="Enter User Name"
                name="username"
                onChange={getNewUser}
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            {/* <Form.Label>
              {t("password")}
              <span className="text-danger h4">*</span> :
            </Form.Label> */}
            <div className='login_parent'>
              <Form.Control
                className='inputLogin'
                type={showPass ? 'password' : 'text'}
                placeholder="Password"
                name="password"
                onChange={getNewUser}
              />
              <div onClick={() => setShowPass(prev => !prev)} className='iconLogin login_showPass'>{showPass ? (<BiShowAlt size={20} />) : (<BiHide size={20} />)} </div>
            </div>
          </Form.Group>

          <Form.Group className="my-3" controlId="formBasicPassword">
            {/* <Form.Label>
              {t("Hotel_Name")}
              <span className="text-danger h4">*</span> :
            </Form.Label> */}
            <div className='login_parent'>
            <Form.Select
              aria-label="Default select example"
              className="w-100 inputLogin"
              name="hotel_name"
              onChange={getNewUser}
            >
              
              <option>{t("Choose_your_hotel")}</option>

              <option value="M3 Hôtel &amp; Résidence Ferney Geneva Airport">
                M3 Hôtel &amp; Résidence Ferney Geneva Airport
              </option>

              <option value="Résidence LA RESERVE Ferney Voltaire, France">
                Résidence LA RESERVE Ferney Voltaire, France
              </option>

              <option value="Zenitude Hôtel Résidences, Ferney Voltaire">
                Zenitude Hôtel Résidences, Ferney Voltaire
              </option>

              <option value="APPART&#x27;CITY CONFORT Genève Aéroport Ferney Voltaire, France">
                APPART&#x27;CITY CONFORT Genève Aéroport Ferney Voltaire, France
              </option>

              <option value="NOVOTEL Geneve Aeroport Ferney Voltaire, France">
                NOVOTEL Geneve Aeroport Ferney Voltaire, France
              </option>

              <option value="Hotel F1 Ferney Voltaire, France">
                Hotel F1 Ferney Voltaire, France
              </option>

              <option value="PREMIERE CLASS Ferney Voltaire, France">
                PREMIERE CLASS Ferney Voltaire, France
              </option>

              <option value="IBIS BUDGET Saint Genis Pouilly, France">
                IBIS BUDGET Saint Genis Pouilly, France
              </option>

              <option value="KYRIAD Saint Genis Pouilly, France">
                KYRIAD Saint Genis Pouilly, France
              </option>

              <option value="PREMIERE CLASS Saint Genis Pouilly, France">
                PREMIERE CLASS Saint Genis Pouilly, France
              </option>

              <option value="IBIS BUDGET Saint Genis Pouilly, France">
                IBIS BUDGET Saint Genis Pouilly, France
              </option>

              <option value="Appart&#x27;hôtel Adagio Saint Genis Pouilly, Fance">
                Appart&#x27;hôtel Adagio Saint Genis Pouilly, Fance
              </option>

              <option value="BEST WESTERN Park Hotel Saint Genis Pouilly">
                BEST WESTERN Park Hotel Saint Genis Pouilly
              </option>

              <option value="WOODS Hotel Genève Thoiry">
                WOODS Hotel Genève Thoiry
              </option>

              <option value="Hôtel JIVA HILL Resort">
                Hôtel JIVA HILL Resort
              </option>

              <option value="Hôtel BOIS JOLY">Hôtel BOIS JOLY</option>

              <option value="Hotel de France Ferney Voltaire, ">
                Hotel de France Ferney Voltaire,{" "}
              </option>

              <option value="Hôtel de France Saint Genis Pouilly, ">
                Hôtel de France Saint Genis Pouilly,{" "}
              </option>

              <option value="Residhome Prévessin Moen Le Carré d&#x27;Or">
                Residhome Prévessin Moen Le Carré d&#x27;Or
              </option>

              <option value="Hotel Motel D&#x27;ornex">
                Hotel Motel D&#x27;ornex
              </option>

              <option value="SEJOUR &amp; AFFAIRES Hotel Saint Genis Pouilly">
                SEJOUR &amp; AFFAIRES Hotel Saint Genis Pouilly
              </option>


            </Form.Select>
            </div>
          </Form.Group>

          <Button variant="warning" type="submit" onClick={logs}>
            Create Account
          </Button>
          <div className="text-center text-danger h3 mt-3">{error}</div>
        </>
      )}
    </div>
  );
}
