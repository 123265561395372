import "./App.css";
import Header from "./Components/Header";
import { Routes, Route } from "react-router-dom";
import Bus from "./Components/Bus";
import { Container } from "react-bootstrap";
import Navbars from "./Components/Navbar";
import Login from "./Components/Login";
import Form from "./Components/Form";
import { Contexts } from "./Context/Context";
import Register from "./Components/Register";
import { RegisterContexts } from "./Context/RegisterContext";
import Taxi from "./Components/Taxi";
import { TaxiContext } from "./Context/TaxiContext";
import Prices from "./Components/Prices";
import Reviews from "./Components/Reviews";
import Search from "./Components/Search";
import WriteUs from "./Components/WriteUs";
import Thanks from "./Components/Thanks";
import Condtions from "./Components/Condtions";
import Privacy from "./Components/Privacy";
import i18next from "i18next";
import { useEffect } from "react";
import Taxi_search from "./Components/Taxi_search";
import Payment from "./Components/Payment";
import PaymentApproved from "./Components/PaymentApproved";
import UnsuccPayment from "./Components/UnsuccPayment";
import Profile from "./Components/Profile";
import { Toaster } from "react-hot-toast";
import Password from "./Components/Password";
import Trips from "./Components/Trips";
import TaxiBooking from "./Components/taxiBooking/TaxiBooking";
import TaxiBookingTrip from "./Components/TaxiBookingTrip";




function App() {
  function lang() {
    if (navigator.language.includes("fr")) {
      i18next.changeLanguage("fr");
    }
  }

  useEffect(() => {
    lang();
  }, []);

  return (
    <div>
      <TaxiContext>
        <Contexts>
          <RegisterContexts>
            <Navbars />
  
              <Container>
                <Routes>
                  <Route path="/home" element={<Header />} />
                  <Route path="/bus" element={<Bus />} />
                  <Route path="/form" element={<Form />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/taxi" element={<Taxi />} />
                  <Route path="/prices" element={<Prices />} />
                  <Route path="/review" element={<Reviews />} />
                  <Route path="/search" element={<Search />} />
                  <Route path="/write" element={<WriteUs />} />
                  <Route path="/Thanks" element={<Thanks />} />
                  <Route path="/Conditions" element={<Condtions />} />
                  <Route path="/Privacy" element={<Privacy />} />
                  <Route path="/TaxiSearch" element={<Taxi_search />} />
                  <Route path="/success" element={<PaymentApproved />} />
                  <Route path="/TaxiMessage" element={<Payment />} />
                  <Route path="/cancelled" element={<UnsuccPayment />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/password" element={<Password />} />
                  <Route path="/Trips" element={<Trips />} />
                  <Route path="/TaxiBooking" element={<TaxiBooking />} />
                  <Route path="/TaxiBookingTrip" element={<TaxiBookingTrip /> } />
                  
                  <Route path="*" element={<Header />} />
                </Routes>
                <Toaster  position="top-center"/>
              </Container>
          </RegisterContexts>
        </Contexts>
      </TaxiContext>
    </div>
  );
}

export default App;
