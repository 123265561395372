import React from 'react'
import Buttons from './Buttons';
import { useEffect } from 'react';
import { useTranslation} from "react-i18next";
import './home.css';
export default function Header() {

  function myFunction() {
    window.scrollTo(0, 0);
     localStorage.removeItem('user')
      localStorage.removeItem('price')
      localStorage.removeItem('reservatio_id')
      localStorage.removeItem('status')
      localStorage.removeItem('busId')
      localStorage.removeItem('shuttle')
  }

  let {t} = useTranslation()

  useEffect(()=>{
    myFunction()
  },[])

  return (
    <div className='text-center'>
        <img alt="taxi" src='/taxi.webp' className="image_header"/>
        <h2 className='pt-2'>Kiro Travel</h2>
        <h3>24/7 {t('available')} </h3>
        <Buttons/>
    </div>
  )
}
