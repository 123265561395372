/* eslint-disable eqeqeq */
import React from "react";
import { Card } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { TbCreditCardOff } from "react-icons/tb";
import { BsCreditCard2Front } from "react-icons/bs";
import { IconContext } from "react-icons";
import { Contextet } from "../Context/Context";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import { toast } from "react-hot-toast";

export default function PaymentApproved() {
  let { setUserForm } = useContext(Contextet);
  let [payment, setPayment] = useState("");
  let [loader, setLoader] = useState(false);
  let [, setUpdate] = useState(false);

  let { t } = useTranslation();

  let navigate = useNavigate();

  function status(call) {
    setLoader(true);
    let x = JSON.parse(localStorage.getItem("booking"));
    if (localStorage.getItem("status") != null) {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("token")}`
      );
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `https://kiro-travel.herokuapp.com/api/v1/shuttle/payment-status/?session_id=${localStorage.getItem(
          "status"
        )}&trip_id=${localStorage.getItem("busId")}&first_name=${x.first_name}&last_name=${x.last_name}&room_number=${x.room}&seats_booked=${x.seats_booked}&no_of_bags=${
          x.no_of_bags
        }&user_id=${localStorage.getItem("user_id")}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          // console.log(result)
          if(result.success) {
            setLoader(false);
            let y = result;
            setPayment(y.message);
            setUpdate(true);
            let z = y.data[0];
            localStorage.setItem("reservatio_id", z.reservation_id);
            localStorage.removeItem("booking")
            localStorage.removeItem("busId")
            localStorage.removeItem("shuttle")
            localStorage.removeItem("status")
          } else {
            navigate("/cancelled")
          }

        })
        .catch((error) => {
          payment("unpaid")
          console.log(error)
        })
        // .finally(() => setLoader(true));
    }
     else {
      toast.error(t("NoPayment"));
      navigate("/");
    }
  }


  useEffect(() => {
    status();
    return () => {
      setUserForm({
        first_name: "",
        last_name: "",
        phone: "",
        no_of_bags: "",
        id: localStorage.getItem("busId"),
        seats_booked: "",
        user: localStorage.getItem("user_id"),
        room: "",
      });
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function display() {
    if (payment != "") {
      if (payment.includes("unpaid")) {
        let x = document.getElementById("declined");
        x.classList.add("display-back");
      } else {
        let x = document.getElementById("approved");
        x.classList.add("display-back");
      }
    }
  }



  useEffect(() => {
    display();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment]);

  return (
    <div className="text-center mt-5">
      {payment ? (
        <>
          <Card
            style={{ width: "fit-content" }}
            className="p-3 m-auto display"
            id="approved"
          >
            <Card.Body>
              <Card.Title className="text-center text-success">
                {t("success")}
              </Card.Title>
              <IconContext.Provider value={{ size: "5em", color: "#28a745" }}>
                <BsCreditCard2Front />
              </IconContext.Provider>
              <h2 className="bold h5 ">{t("payment_approval")}</h2>
              <p className="m-0 p-0">{t("check_email")}</p>
            </Card.Body>
            <div className="text-center">
              <Link to="/thanks" className="btn btn-primary text-white">
                {t("cont")}
              </Link>
            </div>
          </Card>
          <Card
            style={{ width: "fit-content" }}
            className="p-3 m-auto display-dec"
            id="declined"
          >
            <Card.Body>
              <Card.Title className="text-center text-danger">
                {t("unsuccess")}
              </Card.Title>
              <IconContext.Provider value={{ size: "5em", color: "red" }}>
                <TbCreditCardOff />
              </IconContext.Provider>
              <h2 className="bold h5">{t("declined_payment")}</h2>
              <p className="m-0 p-0">{t("another_card")}</p>
            </Card.Body>
            <div className="text-center">
              <Link to="/" className="btn btn-primary text-white">
                {" "}
                {t("home")}
              </Link>
            </div>
          </Card>
        </>
      ) : (
        <div className="text-danger text-center mt-3">
          <h2>{loader == true ? <CircularProgress /> : ""}</h2>
          <Link to="/Back Home"></Link>
        </div>
      )}
    </div>
  );
}
